import {
    mdiFileVideo,
    mdiFileImage,
    mdiFileMusic,
    mdiFilePowerpoint,
    mdiZipBox,
    mdiFileWord,
    mdiFileExcel,
    mdiFilePdfBox, mdiFileCode, mdiFile
} from "@mdi/js";
export function getFileIcon(type) {
    switch(type) {
        // photos
        case 'image/png':
        case 'image/jpeg':
        case 'image/gif':
        case 'image/bmp':
            return mdiFileImage;

        // videos
        case 'video/mp4':
        case 'video/webm':
        case 'video/ogg':
        case 'video/x-msvideo':
            return mdiFileVideo;

        // videos
        case 'audio/midi':
        case 'audio/x-midi':
        case 'audio/mpeg':
        case 'audio/ogg':
        case 'audio/wav':
        case 'audio/webm':
            return mdiFileMusic;

        // archive
        case 'application/x-bzip':
        case 'application/x-bzip2':
        case 'application/epub+zip':
        case 'application/gzip':
        case 'application/zip':
        case 'application/x-7z-compressed':
        case 'application/vnd.rar':
            return mdiZipBox;

        // Office
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return mdiFilePowerpoint;

        case 'application/rtf':
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return mdiFileWord;

        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return mdiFileExcel;

        // PDF
        case 'application/pdf':
            return mdiFilePdfBox;

        // common configuration files
        case 'text/xml':
        case 'application/xml':
        case 'application/json':
            return mdiFileCode;

        default:
            return mdiFile;
    }
}

export function getType(type){
    switch(type) {
        // photos
        case 'image/png':
        case 'image/jpeg':
        case 'image/gif':
        case 'image/bmp':
            return 'image';

        // videos
        case 'video/mp4':
        case 'video/webm':
        case 'video/ogg':
        case 'video/x-msvideo':
            return 'video';

        // videos
        case 'audio/midi':
        case 'audio/x-midi':
        case 'audio/mpeg':
        case 'audio/ogg':
        case 'audio/wav':
        case 'audio/webm':
            return 'audio';

        // archive
        case 'application/x-bzip':
        case 'application/x-bzip2':
        case 'application/epub+zip':
        case 'application/gzip':
        case 'application/zip':
        case 'application/x-7z-compressed':
        case 'application/vnd.rar':
            return 'zip';

        // Office
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return 'file-powerpoint';

        case 'application/rtf':
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'file-word';

        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return 'file-excel';

        // PDF
        case 'application/pdf':
            return 'file-pdf';

        default:
            return 'file';
    }
}

export function prettyPrintFileSize(bytes) {
    if (bytes < 1024) {
        return bytes + ' B';
    } else if (bytes < 1024 * 1024) {
        return (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes < 1024 * 1024 * 1024) {
        return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    } else {
        return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
    }
}