<template>

  <div class="bg-black bg-opacity-25 p-2 m-2 bg-menu rounded-md col-span-2">
    <div class="overflow-hidden">
      <shortenForm/>
    </div>
  </div>

  <short-list v-if="user"/>

</template>

<script>

import shortenForm from '@/components/data/shorturl/shortenForm.vue'
import shortList from "@/components/data/shorturl/shortList.vue";

export default {
  name: 'ShortenURL',
  components: {
    shortenForm,
    shortList
  },
  mounted() {
    this.$store.commit('setTitle', 'Shorten URL')
  },
  methods: {
    submit() {

      if(!this.$refs.url.value) return this.$notify({ type: 'error', text: 'Please enter a URL to shorten'});

      this.$http.post('/shortener', {
        url: this.$refs.url.value,
        custom: this.$refs.custom.value
      }).then(res => {
        this.$notify('Short URL created:' + res.data.url);
      }).catch(err => {
        console.log('err', err);
        this.$notify({ type: 'error', text: err.message, duration: -1})
      })
    }
  },
  computed:{
      user(){
        return this.$store.state.user
      }
  }
}
</script>
