<template>
  <router-link :to="url">
    <div class="">
      <div class="items-center" style="" @mouseover="hover = true" @mouseleave="hover = false">
        <img :src="thumbnail" class="preview bg-menu rounded-md object-cover w-full" :class="{ preview: preview}" />
      </div>
    </div>
  </router-link>
</template>

<script>

import InfiniteLoading from "v3-infinite-loading";
import {getFileIcon} from "@/lib/files";
import {mdiDownload, mdiFileImage} from "@mdi/js";
import SvgIcon from '@jamescoyle/vue-icon'
import {prettyPrintFileSize} from "@/lib/files";

export default {
  name: 'fileItemVideo',
  components: {
    InfiniteLoading,
    SvgIcon
  },
  props: {
    file: Object,
    preview: Boolean
  },
  data() {
    return {
      hover: false
    }
  },
  mounted() {
  },
  methods: {
    download(){
      window.location = this.preview_url;
    }
  },
  computed: {
    filesize(){
      return prettyPrintFileSize(this.file.size);
    },
    thumbnail(){
        return this.hover ? this.file.urls.preview_gif : this.file.urls.thumbnail;
    },
    url(){
      return '/file/' + this.file.id;
    },
  }
}

</script>

<style>

</style>