<template>

  <div v-if="active" class="bg bg-black bg-opacity-90 fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center">

    <div>

      <input ref="search"
             class="text-white text-5xl p-5 w-full md-5 bg-transparent focus:ring-0 focus:ring-offset-0"
             style="height: 100px;"
             type="search"
             v-model="search" @blur="$refs.search.focus()"
             @keydown.enter.stop="use(commands[tabIndex])"
             @keydown.tab.stop="tab"
             @keydown.esc.stop="close()"
             @keydown.shift.stop="1+1"
      >

      <br>
      <p class="text-white mt-2">
       Start typing to search your things, use <kbd>tab</kbd> to navigate, <kbd>enter</kbd> to select, <kbd>esc</kbd> to close.
      </p>

      <br><br><br>

      <div class="results grid grid-cols-3 gap-2" style="width: 80vw; min-width: 250px; max-width: 800px;">
        <div v-for="(item, index) in commands"
             class="p-2 bg-opacity-90 rounded-md border-2 border-solid"
             :class="tabIndex === index ? 'bg-black text-white border-white' : 'bg-white text-black'"
             @click="use(commands[index])"
        >
          <img v-if="item.thumb" :src="item.thumb" alt="Thumbnail" class="inline w-5"/>
          <svg-icon v-else :path="item.icon" type="mdi" class="inline" :ref="'option-'+index" />
          {{item.name}}
        </div>
      </div>
    </div>
  </div>


</template>
<script>

import getNavItems, {apiSearchResultToNavItem} from "@/lib/nav";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiAccount, mdiArrowLeft, mdiCreditCard, mdiLogout, mdiSecurity, mdiUpload} from "@mdi/js";

export default {
  name: "Commandbar",
  components: { SvgIcon },
  props: {
    active: false
  },
  data(){
    return {
      search: '',
      tabIndex: 0,
      previousLocation: null,
      apiSearchResult: [],
      abortController: new AbortController()
    }
  },
  watch: {
    search(){
      this.tabIndex = 0;
      if(this.search.length > 0) {
        this.apiSearchResult = this.$api.v2.get('search?q=' + this.search, {
          signal: this.abortController.signal
        }).then( res => {
          this.apiSearchResult = res.data.map(apiSearchResultToNavItem);
        }).catch( err => {
          if(err.name === 'AbortError') return;
          console.error(err);
        });
      }else{
        this.apiSearchResult = [];
      }
    },
    active(val){
      if(val){
        document.body.classList.add('overflow-hidden');
        this.$nextTick(() => {
          this.search = "";
          this.tabIndex = 0;
          this.$refs.search.focus()
        })
      }else{
        document.body.classList.remove('overflow-hidden')
      }
    }
  },
  computed: {
    user(){ return this.$store.state.user },
    app(){ return this.$store.state.application },
    commands(){

      let items = [];

      if(this.previousLocation){
        items.push({
          name: 'Back',
          icon: mdiArrowLeft,
          link: this.previousLocation
        })
      }

      items.push(...this.$store.state.commandbar.contextual_commands);

      // Add all navbar items.
      items.push(...getNavItems(this.$store.state.user));

      if(this.user){
        items.push({
          name: 'Log Out',
          terms: ['exit'],
          icon: mdiLogout,
          link: '/logout'
        });

        items.push({
          name: 'Account Settings',
          desc: 'Manage email & notification settings',
          terms: ['settings', 'account', 'email'],
          icon: mdiAccount,
          link: '/settings/about'
        });

        items.push({
          name: 'Manage connected accounts',
          desc: '(Dis)connect your Discord/Twitch accounts',
          icon: mdiAccount,
          link: '/settings/about'
        });

        items.push({
          name: 'Billing',
          desc: 'Manage your subscription & view billing cycles',
          icon: mdiCreditCard,
          link: '/settings/billing'
        });

        items.push({
          name: 'API Keys',
          icon: mdiSecurity,
          link: '/settings/api'
        });

        items.push({
          name: 'Upload File',
          icon: mdiUpload,
          link: '/files',
          action: function(){
            setTimeout(() => {
              document.querySelector('#uploadFileButton').click()
            }, 250);
          }
        })

      }

      items = items.filter(item => {
        if(!this.search) return true;

        return (item.terms && item.terms.includes(this.search)) ||
               (item.desc && item.desc.includes(this.search)) ||
               (item.name && item.name.toLowerCase().includes(this.search.toLowerCase()))
      })

      if(this.apiSearchResult.length > 0){
        items.push(...this.apiSearchResult);
      }

      return items;

    },
  },
  methods: {
    tab(e){

      // if shift is held down, go backwards.
      if(e && e.shiftKey) {
        this.tabIndex--;
      }else{
        this.tabIndex++;
      }

      if(this.tabIndex >= this.commands.length){
        this.tabIndex = 0;
      }
      if(this.tabIndex < 0){
        this.tabIndex = this.commands.length - 1;
      }
    },
    close(){
      this.$store.commit('setCommandbar', false)
    },
    use(item){

      this.previousLocation = this.$route.path;

      if(item.link){
        this.$router.push(item.link)
      }

      if(item.action && typeof item.action === 'function'){
        item.action();
      }

      this.close();

    }
  }
}

</script>