<template>

  <div v-if="menuOpen" @click="toggleMenu" class="clickaway backdrop-blur"/>

  <nav>
    <div class="top-bar">
      <svg-icon @click="toggleMenu" type="mdi" :path="mdiMenu" size="48"></svg-icon>
      <div>
        <h1 class="truncate" style="max-width: 65vw; display: block;" v-if="titleVisible && !menuOpen">{{ title }}</h1>
        <h1 v-if="menuOpen">Where to?</h1>
      </div>
      <div class="mr-5">
        <template v-if="user === null">
          <!-- Checking Auth -->
          <user-profile class="loading" :user="{
            id: true,
            avatar: '/img/placeholder.png',
          }" :show-name="false" />
        </template>

        <template v-else-if="user === false">
          <!-- Not Authenticated -->
          <router-link to="/login">Log in</router-link>
        </template>

        <template v-else>
          <!-- Authenticated -->
          <router-link to="/settings/about" @click="closeMenu">
            <user-profile :user="user" :show-name="false"/>
          </router-link>
        </template>
      </div>
    </div>
    <Transition>
      <div class="mobile-menu" v-if="menuOpen">
        <br>
        <hr>
        <br>



        <router-link @click="toggleMenu" v-for="item of navitems" :to="item.link" class="text-white block" active-class="bg-black bg-opacity-25">{{item.name}}</router-link>

        <br>

        <span class="text-base">
          Awoo.Industries
          <span class="text-base" v-if="app?.application?.version">v{{ app?.application?.version }}</span>
        </span>

      </div>
    </Transition>
  </nav>
</template>

<script>

import {mdiMenu} from "@mdi/js";
import {mdiChevronDown} from "@mdi/js";
import SvgIcon from '@jamescoyle/vue-icon'
import {apiURL} from "@/lib/http";
import getNavItems from "@/lib/nav";
import UserProfile from "@/components/ui/user.vue";

export default {
  name: 'navbar',
  data() {
    return {
      menuOpen: false
    }
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen
    },
    closeMenu() {
      this.menuOpen = false
    }
  },
  components: {
    UserProfile,
    SvgIcon
  },
  computed: {
    mdiMenu() {
      return this.menuOpen ? mdiChevronDown : mdiMenu
    },
    navitems(){
      return getNavItems(this.user)
    },
    loggedIn(){
      return !!this.$store.state.user
    },
    app(){
      return this.$store.state.application
    },
    user(){
      return this.$store.state.user
    },
    title() {
      return this.$store.state.title
    },
    titleVisible() {
      return this.$store.state.titleVisible
    }
  }
}
</script>

<style lang="less" scoped>

  @import '@/assets/less/theme.less';

  .clickaway {
    display: block;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 99999;
  }

  .v-enter-active,
  .v-leave-active {
    max-height: 50vh;
  }

  .v-enter-from,
  .v-leave-to {
    max-height: 0;
  }

  nav {
    //themable
    background-color: @header-background-color;
    color: @header-text-color;

    z-index: 9999999;

    padding: 15px;
    position: fixed;
    width: 100vw;
    bottom: 0;

    .top-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .mobile-menu {
      transition: max-height 0.5s ease;
      a {
        display: block;
        text-align: left;
        font-size: 2rem;
      }
    }

  }

  h1 {
    font-size: 1.5rem;
    text-align: center;
  }

</style>