<template>

  <commandbar :active="commandbar" />

  <widget-container-modal />

  <notifications position="top center"
                 :max="3"
                 :width="400"/>

  <responsive :min="$sm">
    <navbar/>
  </responsive>

  <div class="content p-10">
    <router-view/>
  </div>


  <responsive :max="$sm">
    <br><br><br><br>
    <navbar-m/>
  </responsive>

</template>

<script>
import navbar from './components/layout/navbar.vue'
import navbarM from "@/components/layout/navbar.m.vue";
import Responsive from "@/components/screen/Responsive.vue";
import Profile from "@/views/UserSettings.vue";
import {container, config} from "jenesius-vue-modal";
import Commandbar from "@/components/ui/Commandbar.vue";

config({
  animation: "slide-bottom" // Any name
})

export default {
  name: 'App',
  mounted(){

    // Fetch application data from the API
    this.$store.dispatch('getApplicationStatus');
    this.$store.dispatch('checkAuth');
    this.$store.dispatch('connectSocket');

    // Register CMD bar shortcut
    document.addEventListener('keydown', (e) => {

      // dont trigger on input fields
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') return;

      if (e.key === 'k' && e.ctrlKey) {
        e.preventDefault();
        this.$store.commit('setCommandbar', !this.$store.state.commandbar.active)
        console.log('Commandbar', 'Shortcut pressed')
      }
    });

  },
  components: {
    Commandbar,
    Profile,
    Responsive,
    navbarM,
    navbar,
    WidgetContainerModal: container
  },
  computed: {
    commandbar(){
      return this.$store.state.commandbar.active;
    },
  }
}
</script>

<style lang="less">
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  html {
    @apply text-white;
  }

  .interact {
    @apply bg-transparent;
    @apply border-2;
    @apply border-gray-200;
    @apply border-opacity-0;
    @apply rounded-lg;
    @apply px-4;
    @apply py-1;
    @apply transition-colors;
    @apply duration-100;
    @apply cursor-pointer;
  }

  .interact:hover:not(.disabled):not(.readonly) {
    @apply bg-transparent;
    @apply border-2;
    @apply border-treehouse-300;
    @apply bg-white;
    @apply text-black;
  }

  .interact.active:not(.disabled):not(.readonly) {
    @apply bg-transparent;
    @apply border-2;
    @apply bg-white;
    @apply text-black;
  }

  .interact.disabled {
    @apply opacity-25;
  }

  .interact > input {
    @apply border-none;
    @apply outline-none;
    @apply bg-transparent;
    @apply text-white;
  }

	textarea.interact {
		border-radius: 10px;
	}

  .tooltip {
    @apply text-black;
    @apply bg-white;
    @apply rounded-md;
    @apply p-2;
    @apply text-sm;
    @apply z-50;
  }

  kbd {
    @apply bg-white;
    @apply text-black;
    @apply rounded-md;
    @apply px-2;
    @apply py-1;
  }

  .arg {
    @apply bg-black;
    @apply rounded-md;
    @apply px-2;
    @apply py-1;
    @apple border-2;
    @apply text-sm;
    @apply text-red-600;
    @apply border-red-900;
  }

}

.tooltip {
  display: none;
  position: absolute;
  z-index: 1000;
  transform: translateX(-25%) translateY(50%);
}

.modal-container{
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 9999;
  background-color: rgba(25, 25, 25, 0.7);
}

.avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

@keyframes loading {
  0% {
    opacity: 1;
    filter: brightness(1);
  }
  50% {
    opacity: 0.5;
    filter: brightness(1.2);
  }
  100% {
    opacity: 1;
    filter: brightness(1);
  }
}

.loading {
  animation: ease infinite loading 2s;
}

html, body {
  height: 100%;
}

input {
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  color: black;
}

.modal-container{
   align-items: flex-end;
   background-color: rgba(0, 0, 0, 0.47);
 }

.slide-bottom-enter-active,
.slide-bottom-leave-active,
.slide-bottom-enter-active .modal-item,
.slide-bottom-leave-active .modal-item{
  transition: 250ms;
  opacity: 1;
}

.slide-bottom-enter-from .modal-item,
.slide-bottom-leave-to .modal-item{
  transform: translateY(100%);
  opacity: 0;
}

</style>

