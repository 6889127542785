<template>

  <BaseModelStyle class="w-full" style="max-width: 400px;">
    <h2 class="text-2xl">Import from the Internet</h2>
    <p>Import files from certain well-known sites.</p>
    <br>

    <input ref="input" v-model="value" placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ" class="w-full rounded-md"><br><br>
    <button @click="doImport" class="chip">Import</button>
  </BaseModelStyle>

</template>
<script>
import {Modal} from "jenesius-vue-modal";
import BaseModelStyle from "@/components/BaseModelStyle.vue";

export default {
  components: {BaseModelStyle},
  data: () => ({
    value: null
  }),
  mounted() {
    this.$refs.input.focus();
  },
  methods: {
    doImport() {
      this.$emit(Modal.EVENT_PROMPT, this.value);
    }
  }
}
</script>