import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import {lg, md, sm} from "@/lib/screen";
import {Auth, V1, V2} from './lib/http';
import VueAxios from 'vue-axios'
import Notifications from '@kyvg/vue3-notification'

import VueHighlightJS from 'vue3-highlightjs'
import 'highlight.js/styles/atom-one-dark.css'
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import tooltipDirective from "@/directives/tooltip";
import router from "@/router"; //required if you're not going to override default slots

const app = createApp(App);

app.use(store);
app.use(router(store));
app.use(VueAxios, V2);
app.use(Notifications);
app.use(VueHighlightJS);

app.directive('tooltip', tooltipDirective);

app.component("infinite-loading", InfiniteLoading);

app.config.globalProperties.$sm = sm;
app.config.globalProperties.$md = md;
app.config.globalProperties.$lg = lg;

app.config.globalProperties.$api = {
    // v1: V1,
    v2: V2,
    auth: Auth
};


app.mount('#app');


