const tooltipDirective = {
    mounted(el, binding) {
        let position = binding.arg || "top";
        let tooltipText = binding.value || "Tooltip text";

        // Create tooltip element
        const tooltipEl = document.createElement('span');
        tooltipEl.classList.add('tooltip');
        tooltipEl.textContent = tooltipText;
        el.appendChild(tooltipEl);

        // Positioning
        el.classList.add(`tooltip-${position}`);

        // Event listeners to handle the display of tooltip
        el.addEventListener('mouseenter', () => tooltipEl.style.display = 'block');
        el.addEventListener('mouseleave', () => tooltipEl.style.display = 'none');
    },
    beforeUnmount(el) {
        // Clean up event listeners
        el.removeEventListener('mouseenter');
        el.removeEventListener('mouseleave');
    }
};

export default tooltipDirective;
