<template>
  <div class="grid grid-flow-row-dense grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
    <div class="bg-black bg-opacity-25 p-2 m-2 bg-menu rounded-md col-span-2">
      <div class="overflow-hidden p-2 mt-2">
        <file-form/>
      </div>
    </div>
    <file-list-item class="result" v-for="file in files" :key="file.id" :file="file"/>
    <InfiniteLoading @infinite="load" />
  </div>
</template>

<script>

import InfiniteLoading from "v3-infinite-loading";
import fileListItem from "@/components/data/files/fileListItem.vue";

export default {
  name: 'fileList',
  components: {
    InfiniteLoading,
    fileListItem
  },
  data() {
    return {
      files: [],
      page: 0,
      perPage: 25,
    };
  },
  mounted() {

  },
  methods: {

    async load($state){

        try {

          const response = await this.$http.get(`/files?offset=${this.page * this.perPage}&limit=${this.perPage}`);

          if(response.status !== 200) return $state.error();
          if(!response.data || !response.data.data) return $state.error();

          this.files.push(...response.data.data);

          if (response.data.data.length < 10) {
            return $state.complete();
          }

          $state.loaded();
          this.page++;

        } catch (error) {
          $state.error();
        }
    }

  },
  computed: {
    user() {
      return this.$store.state.user
    }
  }
}

</script>

<style lang="less" scoped>

</style>