<template>

  <div class="bg-black bg-opacity-25 p-1 mb-1 bg-menu rounded-md flex gap-4 border-transparent border-2 hover:border-white transition-colors justify-start text-left">
    <svg-icon type="mdi" :path="icon" class="inline" />

    <div v-if="log.action === 'ban'">
      <span class="text-red-500">{{log.data.name}}</span> was banned by <span class="text-red-500">{{log.data.mod_name}}</span>
    </div>

    <div v-else-if="log.action === 'unban'">
      <span class="text-red-500">{{log.data.name}}</span> was unbanned by <span class="text-red-500">{{log.data.mod_name}}</span>
    </div>

    <div v-else-if="log.action === 'stream_start'">
      Stream <span class="text-red-500">{{log.data.title}}</span> was started
    </div>

    <div v-else-if="log.action === 'stream_end'">
      Stream ended
    </div>

    <div v-else-if="log.action === 'follow'">
      <span class="text-red-500">{{log.data.name}}</span> followed
    </div>

    <div v-else-if="log.action === 'subscription'">
      <span class="text-red-500">{{log.data.name}}</span> subscribed with <span class="text-red-500">tier {{log.data.tier}}</span>
    </div>

    <div v-else-if="log.action === 'gift'">
      <span class="text-red-500">{{log.data.name}}</span> gifted <span class="text-red-500">tier {{log.data.tier}} x {{log.data.amount}}</span> subscriptions
    </div>

    <div v-else-if="log.action === 'redeem'">
      <span class="text-red-500">{{log.data.name}}</span> redeemed <span class="text-red-500">{{log.data.reward_name}}</span>
    </div>

    <div class="text-gray-400 text-sm ml-auto block">
      {{date}}
    </div>

  </div>

</template>

<script>

import svgIcon from "@jamescoyle/vue-icon";
import {
  mdiUpload,
  mdiImport,
  mdiMagnify,
  mdiPlus,
  mdiGroup,
  mdiHelp,
  mdiAccountRemove,
  mdiUndo,
  mdiPlay, mdiStop, mdiAccountPlus, mdiCurrencyUsd, mdiGift, mdiCircle, mdiStar, mdiCheckboxMultipleMarkedCircleOutline
} from "@mdi/js";

export default {
  name: 'ActionLog',
  components: {
    svgIcon
  },
  props: {
    log: {
      type: Object,
      required: true,
      default: {
        user: {
          id: 0,
          type: 'user'
        },
        action: 'ban',
        service: 'twitch',
        triggered_by: 0,
        data: {}
      }
    }
  },
  computed: {
    icon(){
      switch(this.log.action){

        case 'stream_start': return mdiPlay;
        case 'stream_end': return mdiStop;

        case 'ban': return mdiAccountRemove;
        case 'unban': return mdiUndo;

        case 'follow': return mdiAccountPlus;
        case 'subscription': return mdiStar;
        case 'gift': return mdiGift;
        case 'redeem': return mdiCheckboxMultipleMarkedCircleOutline;
      }
      return mdiHelp;
    },
    date(){
      return new Date(this.log.createdon).toLocaleString();
    }
  }
}
</script>