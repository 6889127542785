export function getMonth(){
    let d = new Date();
    let month = d.getUTCMonth() + 1;
    return String(month).padStart(2, 0);
}

export function getDay(){
    let d = new Date();
    let month = d.getUTCDate();
    return String(month).padStart(2, 0);
}

export function getHour(){
    let d = new Date();
    let month = d.getUTCHours();
    return String(month).padStart(2, 0);
}

export function getYear(){
    let d = new Date();
    return String(d.getUTCFullYear());
}