<template>

  <div class="text-white p-5">

      <h2>Jobs</h2>

			<div v-for="job in jobs" :key="job.id" class="m-2">
				<h2 class="text-2xl">{{ jobTitle(job) }}</h2>

				<template v-if="job.type === 'import_file'">
					<p>
						Importing from <b>{{ job.data.service }}</b><br>
						{{ job.data.url }}
					</p>
				</template>

				<br>

				<div class="w-full rounded-full h-2.5 bg-slate-900 overflow-hidden">
					<div class="bg-green-500 h-2.5" :style="{width: job.progress + '%'}"></div>
				</div>

			</div>

	</div>

</template>
<script>
import {Modal} from "jenesius-vue-modal";
import Grid from "@/components/grid/Grid.vue";

export default {
  components: {Grid},
  props: {
    filters: {},
  },
  data: () => ({
    interval: null,
  }),
	computed: {
		jobs() {
			return this.$store.state.jobs;
		},
	},
  methods: {
    download(type) {
      this.$emit(Modal.EVENT_PROMPT, type);
    },
    jobTitle(job) {
      switch (job.type) {
        case 'import_file':
          return 'Importing File';
        default:
          return 'Processing';
      }
    },
  },

}
</script>