<template>
	<router-link :to="url">
		<div class="bg-black bg-opacity-25 p-2 m-2 bg-menu rounded-md flex gap-5 border-transparent border-2 hover:border-white transition-colors justify-between text-left">
<!--			<file-item-image v-if="vod.thumbnail" :file="{ urls: {thumbnail: vod.thumbnail}}"/>-->
			<div class="text-center">
				<svg-icon class="inline" type="mdi" :path="getFileIcon('video/mp4')" size="72"/>
			</div>
			<div class="overflow-hidden p-1 mt-2 flex-grow w-full">
				<p class="truncate text-clip">
					<b>{{ vod.title }}</b>
				</p>
        <p class="mt-2 flex gap-2">
          <span v-if="vod.status === 'found'">Queued for download</span>
          <span v-if="vod.status === 'downloading'">Downloading</span>
          <span v-if="vod.status === 'downloaded'">Queued for processing</span>
          <span v-if="vod.status === 'processing'">Processing</span>
          <span v-if="vod.status === 'ready'">Waiting to upload</span>
          <span v-if="vod.status === 'uploading'">Uploading</span>
          <span v-if="vod.status === 'uploaded'">Uploaded</span>
        </p>
<!--        <p class="mt-2 flex gap-2">-->
<!--          <span class="chip readonly"><svg-icon :path="mdiTimer()" type="mdi" size="16" class="inline -mt-0.5"/> {{ vod.duration_formatted }} </span>-->
<!--          <span class="chip readonly"><svg-icon :path="mdiMarker()" type="mdi" size="16" class="inline -mt-0.5"/> {{ vod.markers.length }} </span>-->
<!--        </p>-->
			</div>

      <div class="justify-end">
        <svg-icon type="mdi" :path="mdiChevronRight()" class="inline" />
      </div>

		</div>
	</router-link>
</template>

<script>

import svgIcon from "@jamescoyle/vue-icon";
import FileItemImage from "@/components/data/files/types/image.vue";
import {getFileIcon} from "@/lib/files";
import {mdiChevronRight, mdiMarker, mdiTimer} from "@mdi/js";

export default {
	name: 'VodListItem',
	methods: {
    mdiChevronRight() {
      return mdiChevronRight
    },
    mdiTimer() {
      return mdiTimer
    },
    mdiMarker() {
      return mdiMarker
    }, getFileIcon},
	components: {
		FileItemImage,
		svgIcon,
	},
	props: {
		vod: Object,
	},
	computed: {
		url(){
			return '/vod/' + this.vod.id;
		},
	}
}

</script>

<style lang="less" scoped>

.list-item {
	display: block;
	width: 100%;
	height: 100%;
}
</style>