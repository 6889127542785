export default function getFormattedNumber(value){

    if(value === undefined || value === null){
        return 0;
    }

    if(value < 1000){
        return value;
    }

    if(value < 1000000){
        return (value / 1000).toFixed(2) + 'k';
    }

    if(value < 1000000000){
        return (value / 1000000).toFixed(2) + 'm';
    }
}