<template>
  <div v-if="fetchedUser?.id" :class="{inline: inline, 'px-5': inline}" class="border-transparent hover:border-white border-2 rounded-full" @click="openProfile">
    <img class="avatar" :src="fetchedUser.avatar"/>
    <span class="username" v-if="showName"> {{fetchedUser.username}}</span>
  </div>
</template>

<script>

export default {
  name: 'UserProfile',
  components: {
  },
  props: {
    id: null,
    user: null,
    showName: true,
    inline: false,
  },
  data(){
    return {
      fetchedUser: null,
    }
  },
  mounted(){
    if(this.user) {
      this.fetchedUser = this.user;
    }else{
      this.$http.get('users/' + this.id).then((response) => {
        this.fetchedUser = response.data;
      });
    }
  },
  methods: {
    openProfile(){
      this.$router.push(`/profile/${this.fetchedUser.username}`);
    }
  }
}
</script>

<style scoped lang="less">
@import '@/assets/less/theme.less';
@import '@/assets/less/article.less';

h1 {
  font-size: 2.5em;
  margin-bottom: 0;
}

.inline {

  line-height: 25px;
  vertical-align: middle;

  img {
    display: inline;
    height: 25px;
    width: 25px;
    margin-right: 5px;
    vertical-align: middle;
  }
}

</style>
