<template>
  <slot v-if="isVisible"></slot>
</template>

<script>

import debounce from "@/lib/debounce";

export default {
  name: 'Responsive',
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: Infinity
    }
  },
  data() {
    return {
      width: 0,
      changeWidth: debounce(() => {
        this.width = window.innerWidth;
      }, 0)
    }
  },
  mounted() {
    window.addEventListener("resize", this.changeWidth);
    this.width = window.innerWidth
  },
  unmounted() {
    window.removeEventListener("resize", this.changeWidth);
  },
  computed: {
    isVisible(){
      return this.width > this.min && this.width < this.max;
    }
  }
}
</script>

<style lang="less" scoped>

@import '@/assets/less/theme.less';

nav {
  //themable
  background-color: @header-background-color;
  color: @header-text-color;

  padding: 25px;
}

</style>