<template>


    <div class="flex gap-5">

      <div id="sidebar" class="border-white border-2 rounded-md h-full p-5 gap-5" style="width: 300px; min-width: 300px; max-width: 300px;">

        <div class="grid grid-cols-1 md:grid-cols-2 gap-2">

          <!-- Upload File -->
          <button class="interact" @click="$refs.fileupload.click()" id="uploadFileButton">
            <input type="file" multiple name="file" id="fileInput" class="hidden" @change="fileInputChanged" ref="fileupload" :accept="allowedUploadTypes">
            <svg-icon type="mdi" :path="mdiUpload" class="inline" />
            Upload
            <!-- Upload Progress -->
            <div v-if="uploadProgress > 0" class="h-1 bg-gray-800 rounded-full">
              <div class="h-1 bg-blue-500" :style="`width: ${uploadProgress}%`"></div>
            </div>
          </button>

          <!-- Import File -->
          <button class="interact" @click="showImportDialog()">
            <svg-icon type="mdi" :path="mdiImport" class="inline" />
            Import
          </button>

        </div>

        <hr class="my-5"/>

        <div class="w-full grid grid-cols-1 gap-1">

          <router-link class="interact w-full truncate" active-class="active" to="/files/uploads">
            <svg-icon type="mdi" :path="mdiClock()" class="inline" />
            Recent Uploads
          </router-link>
          <router-link class="interact w-full truncate" active-class="active" to="/files/shared">
            <svg-icon type="mdi" :path="mdiEarth()" class="inline" />
            Shared Files
          </router-link>
          <router-link class="interact w-full truncate" active-class="active" to="/files/deleted">
            <svg-icon type="mdi" :path="mdiTrashCan()" class="inline" />
            Deleted Recently
          </router-link>

        </div>

          <hr class="my-5"/>

        <grid ref="collectiongrid" endpoint="collections" filters="&type=system_collection" :mode="'w-full grid grid-cols-1 gap-1'">
          <template #items="props">
            <router-link class="interact w-full truncate" :to="'/files/collection/' + collection.id" v-for="collection in props.list" :key="collection.id">
              <dynamic-icon type="mdi" :icon="collection.icon" class="inline"/>
              {{ collection.name }}
            </router-link>
          </template>
        </grid>

        <hr class="my-5"/>

        <grid ref="collectiongrid" endpoint="collections" filters="&type=user_created" :mode="'w-full grid grid-cols-1 gap-1'">
          <template #items="props">
            <router-link class="interact w-full truncate" :to="'/files/collection/' + collection.id" v-for="collection in props.list" :key="collection.id">
              <dynamic-icon type="mdi" :icon="collection.icon" class="inline"/>
              {{ collection.name }}
            </router-link>
            <a @click.stop="showCreateCollectionDialog" class="interact w-full truncate" to="/files/recent">
              <svg-icon type="mdi" :path="mdiPlusCircle()" class="inline" />
              New Collection
            </a>
          </template>
        </grid>

      </div>

      <div class="grow">
        <router-view class="col-span-10"  :key="$route.fullPath" />
      </div>

    </div>



</template>

<script>

import Grid from "@/components/grid/Grid.vue";
import fileListItem from "@/components/data/files/fileListItem.vue";
import svgIcon from "@jamescoyle/vue-icon";
import {
  mdiUpload,
  mdiImport,
  mdiMagnify,
  mdiPlus,
  mdiGroup,
  mdiClock,
  mdiMonitorScreenshot,
  mdiShare,
  mdiDownload, mdiRabbitVariant, mdiFishbowl, mdiConsole, mdiPlusCircle, mdiTrashCan, mdiEarth
} from "@mdi/js";
import {openModal, promptModal} from "jenesius-vue-modal";
import ImportFromURL from "@/components/modals/ImportFromURL.vue";
import DynamicIcon from "@/components/dynamic_icon.vue";
import CreateCollection from "@/components/modals/CreateCollection.vue";
import JobsList from "@/components/modals/jobsList.vue";

export default {
  name: 'FileListView',
  components: {
    JobsList,
    DynamicIcon,
    svgIcon,
    Grid,
    fileListItem,
  },
  data() {
    return {

      mdiUpload: mdiUpload,
      mdiImport: mdiImport,
      mdiSearchWeb: mdiMagnify,
      mdiPlus: mdiPlus,
      mdiGroup: mdiGroup,

      uploadProgress: 0,
      search: "",
      selected_group: null,
      selected_mime: "",

    };
  },

  mounted() {
    this.$store.commit('setTitle', 'My Files');
  },
  methods: {
    mdiEarth() {
      return mdiEarth
    },
    mdiTrashCan() {
      return mdiTrashCan
    },
    mdiPlusCircle() {
      return mdiPlusCircle
    },
    mdiDownload() {
      return mdiDownload
    },
    mdiShare() {
      return mdiShare
    },
    mdiClock() {
      return mdiClock
    },
    reload(){
      this.$refs.filegrid.reset();
    },
    async fileInputChanged(e) {
      for(const file of this.$refs.fileupload.files) {
        this.uploadFile(file);
      }
      this.$refs.fileupload.value = null;
    },
    uploadFile(file_data) {
      const name = file_data.name;

      // Request a signed URL from the API
      this.$api.v2.post('/files', {
        filename: name,
        'content-type': file_data.type ? file_data.type : 'application/octet-stream',
      }).then(res => {
        this.progress = 10;

        const formData = new FormData();
        Object.keys(res.data.upload_to.fields).forEach(key => {
          formData.append(key, res.data.upload_to.fields[key]);
        });

        // Actual file has to be appended last.
        formData.append("file", file_data);

        const xhr = new XMLHttpRequest();
        xhr.upload.addEventListener('progress', (event) => {
          console.log('progress', event);
          if (event.lengthComputable) {
            this.uploadProgress = (event.loaded / event.total) * 100;
          }
        });
        xhr.onload = () => {
          if(xhr.status === 204){
            this.uploadProgress = 100;

            this.$api.v2.put('/files/' + res.data.id, {
              status: 'uploaded',
            }).then(res => {
              this.uploadProgress = 0;
              this.$emit('uploaded', res.data);
              this.reload();
            }).catch(err => {
              this.uploadProgress = 0;
              this.reload();
              this.$notify({
                title: "Upload Failed",
                text: "Something went wrong while uploading the file.",
                type: "error"
              });
            });

          }else{
            this.uploadProgress = 0;
            this.reload();
            this.$notify({
              title: "Upload Failed",
              text: "Something went wrong while uploading the file.",
              type: "error"
            });
          }
        };

        xhr.open("POST", res.data.upload_to.url, true);
        xhr.send(formData);

      })

    },

    async showImportDialog() {
      const modal = await promptModal(ImportFromURL);
      this.$api.v2.post('/imports', {
        url: modal
      }).then( result => {
        this.$notify({
          title: "Import Started",
          text: "Your import has been started. It may take a few minutes to complete.",
          type: "success"
        });
      }).catch( err => {
        this.$notify({
          title: "Import Failed",
          text: err.message,
          type: "error"
        });
      })
    },

    async showCreateCollectionDialog() {
      const modal = await promptModal(CreateCollection);
      this.$api.v2.post('/collections', modal).then( result => {
        this.$router.push(`/files/collection/${result.data.id}`);
      }).catch( err => {
        this.$notify({
          title: "Collection Creation Failed",
          text: err.message,
          type: "error"
        });
      })
    }

  },
  computed:{
    filters(){
      let filters = '';
      if(this.search) filters += `&search=${encodeURIComponent(this.search)}`;
      if(this.selected_group) filters += `&collection=${this.selected_group}`;
      if(this.selected_mime) filters += `&type=${this.selected_mime}`;
      return filters;
    },
    user(){
      return this.$store.state.user
    },
    allowedUploadTypes(){
      return '*, */*'
    }
  }
}
</script>
<style scoped lang="less">

@import '@/assets/less/theme.less';

</style>
