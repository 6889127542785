<template>

  <h1 class="font-title text-2xl">Collections</h1>
  <p>When you request a list of objects, usually trough a endpoint without specifying a ID you will receive a collection response. A Collection is a list of objects of the same type. If a collection contains no items, a HTTP 204 "No Content" is returned instead.</p><br>

  <h1 class="font-title text-2xl">Identifying Collections</h1>
  It's useful to know when the response you receive is a collection.
  You can do this by checking the <code>meta</code> property of the response.
  If the property exists, and it's <code>type</code> property is "awoo::collection", congratulations it's a collection!<br><br>

  <h1 class="font-title text-2xl">Limits</h1>
  You can use the GET parameter `limit` to specify the maximum number of items to return in the list. This value is capped at 100 per request.<br>
  <Codeblock method="get" :url="`${apiBASE()}api/v2/files?limit=2`">
  </Codeblock><br>
  <h1 class="font-title text-2xl">Offset/Pagination</h1>
  You can use the GET parameter `offset` to specify the number of items to skip in the list. This is useful for pagination.<br>
  <Codeblock method="get" :url="`${apiBASE()}api/v2/files?offset=1&limit=1`">
  </Codeblock><br>
</template>

<script>

import Codeblock from "@/components/layout/codeblock.vue";
import {apiBASE} from "@/lib/http";

export default {
  name: 'Api Documentation - Collections',
  components: {Codeblock},
  mounted() {
    this.$store.commit('setTitle', 'Api Documentation')
  },
  methods: {
    apiBASE() {
      return apiBASE
    }
  },
  computed: {
    list_example() {
      return {
        "data": [
          {
            "id": "1"
          },
          {
            "id": "2"
          }
        ],
        "meta": {
          "limit": 2,
          "offset": 0,
          "total": 100
        }
      }
    },
    list_example_offset(){
      return {
        "data": [
          {
            "id": "26"
          },
          {
            "id": "27"
          },
          {
            "id": "28"
          },
        ],
        "meta": {
          "limit": 3,
          "offset": 25,
          "total": 100
        }
      }
    },
  }
}
</script>
