<template>
  <nav class="select-none">

    <div class="flex justify-left">

      <div class="flex gap-2 text-gray-900 px-2 py-2 text-white w-full border-white border-bottom-2">

        <template v-if="user === null">
          <!-- Checking Auth -->
          <user-profile class="loading" :user="{
            id: true,
            avatar: '/img/placeholder.png',
          }" :show-name="false" />
        </template>

        <template v-else-if="user === false">
          <!-- Not Authenticated -->

          <router-link to="/login"
                       class="block p-2 px-3 aspect-square rounded-full bg-white bg-opacity-0 hover:bg-opacity-75 hover:text-black"
                       v-tooltip="'Log In'">
            <svg-icon :path="mdiLogin" type="mdi" class="inline" />
          </router-link>
        </template>

        <template v-else>
          <router-link to="/settings/about">
            <user-profile :user="user" :class="{'border-white border-2': $route.path.includes('/settings/')}"  :show-name="false"/>
          </router-link>
        </template>

        <router-link
            v-for="item of navitems"
            :key="item.name"
            :to="item.link"
            class="block p-2 px-3 aspect-square rounded-full bg-white bg-opacity-0 hover:bg-opacity-75 hover:text-black"
            active-class="bg-white bg-opacity-25"
            v-tooltip="item.name">
          <svg-icon :path="item.icon" type="mdi" class="inline" />
        </router-link>

        <div
            class="block p-2 px-3 aspect-square rounded-full bg-white bg-opacity-0 hover:bg-opacity-75 hover:text-black"
            v-tooltip="'Search'"
            @click="$store.commit('setCommandbar', true)">
          <svg-icon :path="mdiMagnify()" type="mdi" class="inline" />
        </div>


      </div>


    </div>

  </nav>

</template>

<script>

import UserProfile from "@/components/ui/user.vue";
import getNavItems from "@/lib/nav";
import {mdiInbox, mdiLogin, mdiMagnify} from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import JobsList from "@/components/modals/jobsList.vue";

export default {
  name: 'navbar',
  methods: {
    mdiMagnify() {
      return mdiMagnify
    }
  },
  data: () => ({
    mdiInbox: mdiInbox,
    mdiLogin: mdiLogin,
  }),
  components: {JobsList, UserProfile, SvgIcon},
  computed: {
		jobCount(){
			return this.$store.getters['getJobCount'];
		},
    login_url(){
      return  `https://${process.env.VUE_APP_API_HOST}auth/discord/login`
    },
    navitems(){
      return getNavItems(this.user)
    },
    user(){
      return this.$store.state.user
    },
    app(){
      return this.$store.state.application
    },
    title() {
      return this.$store.state.title
    },
    titleVisible() {
      return this.$store.state.titleVisible
    }
  }
}
</script>

<style lang="less">

</style>