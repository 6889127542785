<template>

<div class="overflow-hidden bg-white text-white shadow sm:rounded-lg">
  <div class="px-4 py-5 sm:px-6">
    <h3 class="text-base font-semibold leading-6 text-gray-900">Discord bots</h3>
    <p class="mt-1 max-w-2xl text-sm text-gray-500">Discord bots hosted & maintained by me.</p>
  </div>
  <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
    <dl class="sm:divide-y sm:divide-gray-200 list-none">
      <li class="p-5 text-black" v-for="bot of bots" :key="bot.name">
        <h1 class="text-xl">{{bot.name}}</h1>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          {{bot.description}}<br><br>
        </p>
        <div>
          <a :href="bot.invite" target="_blank" class="rounded-md p-2 mt-10 bg-blue-500 text-white w-full">Invite</a>
        </div>
        <br>
        <div>
          <a v-for="link in bot.links" :href="link.url" class="rounded-md p-2 bg-gray-200">{{link.name}}</a>
        </div>
      </li>
    </dl>
  </div>
</div>

</template>

<script>


export default {
  name: 'DiscordBots',
  components: {},
  data: () => {
    return {
      bots: [
        {
          name: "Steam Linker",
          description: "Steam Linker makes it easier to share steam content with friends, No more logging into a browser to subscribe to that workshop item, no longer switching to steam chat just to send a link.",
          invite: "https://discord.com/oauth2/authorize?client_id=760573274605355118&permissions=274877908992&scope=bot%20applications.commands",
          links: [
            {
              name: "Privacy Policy",
              url: "/bots/sl/privacy"
            }
          ]
        }
      ]
    }
  },
  mounted() {
    this.$store.commit('setTitle', 'Discord bots')
  },
  methods: {

  },
  computed: {
  }
}
</script>
