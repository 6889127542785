<template>

  <BaseModelStyle>
    <h2 class="text-2xl">Choose a download</h2><br>

    <div class="border-orchid-400 border-2 w-full p-2 mb-5 cursor-pointer" @click="download('full')">
      <h3 class="text-xl">Original File</h3>
      <p>Download the original file </p>
    </div>

    <div class="border-white border-2 w-full p-2 mb-5 cursor-pointer" v-if="urls.thumbnail" @click="download('thumbnail')">
      <h3 class="text-xl">Thumbnail</h3>
      <p>A 256px thumbnail of the image/video.</p>
    </div>

    <div class="border-white border-2 w-full p-2 mb-5 cursor-pointer" v-if="urls.thumbnail_hd" @click="download('thumbnail_hd')">
      <h3 class="text-xl">HD Thumbnail</h3>
      <p>A HD still from the video.</p>
    </div>

    <div class="border-white border-2 w-full p-2 mb-5 cursor-pointer" v-if="urls.preview_gif" @click="download('preview_gif')">
      <h3 class="text-xl">Preview GIF</h3>
      <p>A short gif representing the video.</p>
    </div>

    <div class="border-white border-2 w-full p-2 mb-5 cursor-pointer" v-if="urls.waveform" @click="download('waveform')">
      <h3 class="text-xl">Waveform</h3>
      <p>a PNG representing the waveform of the audio file.</p>
    </div>

    <div v-if="urls.fools24_blue || urls.fools24_red">
      <span class="bg-blue-800 text-white rounded-lg px-2 py-1 mr-4">NEW!</span>
      <span class="text-2xl">Try something a little different</span><br><br>
      <p class="mb-4">Using our new revolutionary and copyrighted, patented and propitiatory RGB colors (implemented by every vendor) you can now re-style your images using our three perfect presets. This high-tech solution uses AI (Alternative Images) to achieve this perfect result.</p>
      <div class="grid grid-cols-3 gap-5">


        <div class="border-blue-500 border-2 w-full p-2 mb-5 cursor-pointer" v-if="urls.fools24_blue" @click="download('fools24_blue')">
          <h3 class="text-xl">Blue</h3>
          <p>"The shade of a wizard's favorite socks, lost in a spell gone awry"</p>
        </div>

        <div class="border-red-500 border-2 w-full p-2 mb-5 cursor-pointer" v-if="urls.fools24_blue" @click="download('fools24_red')">
          <h3 class="text-xl">Red</h3>
          <p>"The color of a dragon's blush when it's caught reading romantic poetry"</p>
        </div>

        <div class="border-green-500 border-2 w-full p-2 mb-5 cursor-pointer" v-if="urls.fools24_blue" @click="sad">
          <h3 class="text-xl">Green</h3>
          <p>"The hue of an envious alien, eyeing Earth's abundance of tacos"</p>
        </div>

      </div>
    </div>

  </BaseModelStyle>

</template>
<script>
import {Modal} from "jenesius-vue-modal";
import BaseModelStyle from "@/components/BaseModelStyle.vue";

export default {
  components: {BaseModelStyle},
  props: {
    urls: null,
    filename: ''
  },
  methods: {
    download(type) {
      this.$emit(Modal.EVENT_PROMPT, type);
    },
    sad(){
      this.$notify({title: 'Something went wrong', text: 'An error occurred during the creativity assessment: Green is not a creative color.',type: 'error' });
      let sfx = new Audio('/f24/sadtrombone.mp3');
      sfx.play();
    }
  }
}
</script>