<template>

  <div class="grid grid-cols-1 gap-2">

    <!-- ID -->
    <div class="box p-2 grid grid-cols-1 md:grid-cols-2">

      <div>
        <p class="text-xl">id</p>
      </div>

      <span>{{ user.id }}</span>

    </div>

    <!-- Username -->
    <div class="box p-2 grid grid-cols-1 md:grid-cols-2">

      <div>
        <p class="text-xl">username</p>
      </div>

      <span>{{ user.username }}</span>

    </div>

    <!-- Email -->
    <div class="box p-2 grid grid-cols-1 md:grid-cols-2">

      <div>
        <p class="text-xl">email</p>
        <span>Where we can send you annoying spam.</span>
      </div>

      <div v-if="changing_email">
        <input type="email" :value="user.email" class="rounded-md border-white border-2 p-2 w-full" @keydown.enter.stop="changeUserEmail" @blur="changing_email = false;">
      </div>
      <div v-else @click="changing_email = true;" class="cursor-pointer align-middle">
        <svg-icon :path="mdiPencil()" type="mdi" class="inline cursor-pointer" /> {{ user.email || "No email provided." }}
      </div>

    </div>

    <!-- Timezone -->
    <div class="box p-2 grid grid-cols-1 md:grid-cols-2" v-if="user.linked_accounts.find(acc => acc.provider === 'discord')">

      <div>
        <p class="text-xl">timezone</p>
      </div>

      <div class="mt-2 align-middle">
        <select class="bg-transparent text-white" @change="changeTimezone" :value="user?.metadata['timezone']">
          <option>None</option>
          <option v-for="zone in timezones" :value="zone">{{zone}}</option>
        </select>
      </div>

    </div>

    <!-- Discord Event Server -->
    <div class="box p-2 grid grid-cols-1 md:grid-cols-2" v-if="user.linked_accounts.find(acc => acc.provider === 'discord')">

      <div>
        <p class="text-xl">schedule discord</p>
        <span>Which discord server should we create events for your stream schedule?</span>
      </div>

      <div class="mt-2 align-middle">
        <select class="bg-transparent text-white" @change="changeScheduleDiscord" :value="user?.metadata['streamsuite_schedule_discord_server_id']">
          <option>None</option>
          <option v-for="server in discord_servers" :value="server.id">{{server.name}}</option>
        </select>
      </div>

    </div>

  </div>

</template>
<script>
import {mdiPencil} from "@mdi/js";
import svgIcon from "@jamescoyle/vue-icon";
import {listTimeZones} from "timezone-support";

export default {
  name: 'UserSettings',
  components: {
    svgIcon
  },
  data() {
    return {
      changing_email: false
    }
  },
  methods: {
    mdiPencil() {
      return mdiPencil
    },
    changeUserEmail(e) {
      this.$api.v1.put('/users/' + this.user.id, {
        email: e.srcElement.value,
      }).then(() => {
        this.changing_email = false;
        this.$store.dispatch('checkAuth');
      });
    },
    changeScheduleDiscord(e) {
      this.$api.v1.put('/user_metadata', {
        key: 'streamsuite_schedule_discord_server_id',
        value: e.srcElement.value,
      }).then(() => {
        this.$store.dispatch('checkAuth');
      });
    },
    changeTimezone(e) {
      this.$api.v1.put('/user_metadata', {
        key: 'timezone',
        value: e.srcElement.value,
      }).then(() => {
        this.$store.dispatch('checkAuth');
      });
    },
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    discord_servers() {
      return this.$store.state.user?.valid_discord_servers;
    },
    timezones(){
      return listTimeZones();
    }
  },
  watch: {
  }
}
</script>