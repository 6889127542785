<template>
  <div>
    <slot name="filters"/>
  </div>
  <div :class="mode">

      <slot name="items" :list="list">
        <span>No slot content</span>
      </slot>
    <span slot="no-more" class="hidden"></span>
    <InfiniteLoading :identifier="filters + '&reset=' + this.unique" @infinite="load">
      <template #error="{ retry }">
        <button @click="retry">Retry</button>
      </template>
      <template #complete>
        <span> </span>
      </template>
    </InfiniteLoading>
  </div>
</template>

<script>

import InfiniteLoading from "v3-infinite-loading";
import fileListItem from "@/components/data/files/fileListItem.vue";

export default {
  name: 'Grid',
  components: {
    InfiniteLoading,
  },
  props: {
    mode: {
      type: String,
      default: 'grid grid-flow-row-dense grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6'
    },
    filters: {
      type: String,
      default: ''
    },
    endpoint: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      list: [],
      page: 0,
      perPage: 25,
      unique: 0,
      resetTimeout: 0,
    };
  },
  mounted() {

  },
  methods: {

    async load($state){

      try {

        const response = await this.$api.v2.get(`/${this.endpoint}?offset=${this.page * this.perPage}&limit=${this.perPage}${this.filters}`);

        if(response.status === 204){
          return $state.complete();
        }

        if(response.status !== 200) return $state.error();
        if(!response.data || !response.data.data) return $state.error();

        this.list.push(...response.data.data);

        if (response.data.data.length < this.perPage) {
          return $state.complete();
        }

        $state.loaded();
        this.page++;

      } catch (error) {
        $state.error();
      }

    },

    reset(){

      clearTimeout(this.resetTimeout);

      this.resetTimeout = setTimeout(() => {
        this.list = [];
        this.page = 0;
        this.unique++;
      }, 250);

    }

  },
  watch: {
    filters: {
      handler: function (val, oldVal) {
        this.reset();
      },
      deep: true
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  }
}

</script>

<style lang="less" scoped>
.no-more {
  display: none;
}
</style>