<template>
  <h1 class="font-title text-2xl">Welcome</h1>
  <p>Welcome to the Awoo.industries Api documentation.<br>Please pick a topic or endpoint from the sidebar to get started.</p>
</template>

<script>


import Entry from "@/components/api/entry.vue";

export default {
  name: 'Api Documentation Welcome',
  components: {Entry},
  mounted() {
    this.$store.commit('setTitle', 'Api Documentation')
  },
  methods: {

  },
  computed: {
    api() {
      return this.$store.state?.application?.application?.documentation?.api;
    }
  }
}
</script>
