<template>

  <h1 class="text-3xl">What is Awoo.Industries</h1>
  <p>Awoo.Industries started as just awoo.download in 2015. A small website for sharing screenshots for me and some friends. It's been remade from the ground up so much I've lost count.<br>
  Although, the previous version was labeled as 27, so this one is 28.</p>

  <h1 class="text-3xl mt-10">Uploading files</h1>
  <p>Because you can't trust random strangers on the internet I either need to know you or you'll need to support me in some way (read: get the supporter role on the discord)</p>

  <h1 class="text-3xl mt-10">What's next?</h1>
  <p>Here are some things i'd like to add in the future</p>
  <ul>
    <li>Burner Emails (using awoo.x domains)</li>
    <li>Game server reservations</li>
    <li>Statistics for uploads/shortlinks</li>
  </ul>

  <h1 class="text-3xl mt-10">Keeping track</h1>
  <p>Better uptime lets you have bigger limits if you rep them on your sites so, here's the shill:</p><br>
  <a target="_blank" href="https://betteruptime.com/"><img style="width: 130px; height: 52px;" alt="Better Uptime Website Monitoring" src="https://betteruptime.com/assets/static_assets/badges/dark.png" /></a>

</template>

<script>

export default {
  name: 'HomeView',
  components: {
  },
  mounted() {
    this.$store.commit('setTitle', 'Home')
  }
}
</script>
