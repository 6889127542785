<template>
  <svg-icon type="mdi" :path="computedIcon" class="inline" />
</template>

<script>

import svgIcon from "@jamescoyle/vue-icon";
import {
  mdiUpload,
  mdiImport,
  mdiMagnify,
  mdiPlus,
  mdiGroup,
  mdiHelp,
  mdiMonitorScreenshot,
  mdiConsole,
  mdiFolder
} from "@mdi/js";
import {availableIcons} from "@/lib/icons";

export default {
  name: 'DynamicIcon',
  components: {
    svgIcon
  },
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    computedIcon(){
      return availableIcons[this.icon] ?? mdiHelp;
    }
  }
}
</script>