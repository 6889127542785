<template>
  <div class="monospace text-sm border-black border-2 rounded-md bg-black block p-5 mt-5" @click="small = false">
    <slot></slot>
    <div class="flex">
      <p class="ml-2 text-white text-opacity-75 mb-5" v-if="url">
        <span class="rounded-md bg-gray-700 px-2 py-1 text-xs text-white text-opacity-75 mr-2" v-if="method">{{ method.toUpperCase() }}</span>
        {{ url }}
      </p>
      <a v-if="canTry" class="ml-auto cursor-pointer px-2 py-1" @click="dotry">Try it</a>
    </div>
    <pre v-highlightjs><code class="ml-2 block whitespace-pre overflow-scroll rounded-md p-5" :style="smallCss">{{ text }}</code></pre>
  </div>
</template>

<script>
import { createApp } from 'vue'
import VueHighlightJS from 'vue3-highlightjs'
import 'highlight.js/styles/solarized-dark.css'
export default {
  name: 'Codeblock',
  props: {
    title: "",
    method: null,
    url: "",
    rawText: "",
  },
  data: () => ({
    text: "",
    small: true,
  }),
  mounted() {
    if(!this.url || this.method == null){
      this.text = this.rawText;
    }else{
      this.$http.get(this.url).then((response) => {
        this.text = JSON.stringify(response.data, null, 2);
      }).catch((error) => {
        this.text = JSON.stringify(error.response.data, null, 2);
      });
    }
  },
  computed: {
    smallCss(){
      return this.small ? "max-height: 200px; overflow-y: scroll;" : "";
    },
    canTry(){
      if(!this.method || this.method.toUpperCase() !== 'GET'){ return false }
      try {
        let url = new URL(this.url);
        return true;
      }catch(ex){
        return false;
      }
    }
  },
  methods: {
    dotry(){
      window.open(this.url, '_blank');
    }
  }
}

</script>