import {mdiBug, mdiFile, mdiFolder, mdiLink, mdiPassport, mdiVideo} from "@mdi/js";

export function apiTypeToIcon(result){
    switch (result['_search_type']) {
        case "file": return mdiFile;
        case "character": return mdiPassport;
        case "vods": return mdiVideo;
        default : return mdiBug;
    }
}
export function apiTypeToTitle(result){
    switch (result['_search_type']) {
        case "file": return result.filename;
        case "character": return result.name;
        case "vods": return result.title;
        default : return result['_search_type'];
    }
}

export function apiSearchResultToNavItem(result){

    let obj =  {
        name: apiTypeToTitle(result),
        icon: apiTypeToIcon(result),
        thumb: result?.urls?.thumbnail,
    }

    switch (result['_search_type']) {
        case "file": obj.link = `/file/${result.id}`; break;
        case "character": obj.link = `/character/${result.id}`; break;
        case "vods": obj.link = `/vod/${result.id}`; break;
    }

    return obj;

}

export default function getNavItems(user){

    return [
        {
            name: 'My Stuff',
            terms: ['download', 'upload', 'files', 'my files', 'drive'],
            icon: mdiFolder,
            link: '/files',
        },
        {
            name: 'Short URLS',
            terms: ['to', 'links'],
            icon: mdiLink,
            link: '/links',
        }
    ];

};
