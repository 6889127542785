import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Shorten from "@/views/data/ShortenURL.vue";
import FileUploads from "@/views/files/list.vue";
import FileGrid from "@/views/files/items.vue";
import FileView from "@/views/files/detail.vue";
import ShortDetail from "@/views/data/ShortDetail.vue";
import DiscordBots from "@/views/DiscordBots.vue";
import Docs from "@/views/api/docs.vue"
import Authentication from "@/views/api/topics/authentication.vue";
import Errors from "@/views/api/topics/errors.vue";
import Welcome from "@/views/api/topics/welcome.vue";
import Lists from "@/views/api/topics/collections.vue";
import LoginView from "@/views/auth/login.vue";
import UserSettings from "@/views/UserSettings.vue";
import UserSettingsAbout from "@/views/user_settings/About.vue";
import UserSettingsApi from "@/views/user_settings/Api.vue";
import UserSettingsBilling from "@/views/user_settings/Billing.vue";
import VodListView from "@/views/vods/list.vue"
import Endpoint from "@/views/api/topics/endpoint.vue";
import {apiBASE} from "@/lib/http";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/settings',
    name: 'settings',
    component: UserSettings
  },
  {
    path: '/settings',
    name: 'profileSettings',
    component: UserSettings,
    children: [
      {
        path: '/settings/about',
        name: 'profileSettingsAbout',
        component: UserSettingsAbout,
      },
      {
        path: '/settings/api',
        name: 'profileSettingsApi',
        component: UserSettingsApi,
      },
      {
        path: '/settings/billing',
        name: 'profileSettingsBilling',
        component: UserSettingsBilling,
      }
    ]
  },
  {
    path: '/links',
    name: 'Shorten URL',
    component: Shorten
  },
  {
    path: '/link/:id',
    name: 'Short URL',
    component: ShortDetail,
    props: true,
  },
  {
    path: '/files',
    name: 'My Files',
    component: FileUploads,
    children: [
      {
        path: 'uploads',
        name: 'Recent Uploads',
        component: FileGrid,
        props: {
          public: null,
          display_by_date: true,
        }
      },
      {
        path: 'shared',
        name: 'Shared Files',
        component: FileGrid,
        props: {
          public: true,
        }
      },
      {
        path: 'deleted',
        name: 'Deleted Files',
        component: FileGrid,
        props: {
          scheduled_for_deletion: true,
        }
      },
      {
        path: 'collection/:id',
        name: 'Collection',
        component: FileGrid,
        props: {
          archived: true,
        }
      }
    ]
  },
  {
    path: '/file/:id',
    name: 'File View',
    component: FileView,
    props: true
  },
  {
    path: '/bots',
    name: 'Bots',
    component: DiscordBots
  },
  {
    path: '/vods',
    name: 'VODs',
    component: VodListView
  },
  {
    path: '/api/docs',
    name: 'Api Docs',
    component: Docs,
    children: [

      // index
      {
        path: '',
        name: 'Api Docs - Index',
        redirect: '/api/docs/intro',
      },

      // topics
      {
        path: 'intro',
        name: 'Api Docs - Intro',
        component: Welcome,
      },
      {
        path: 'auth',
        name: 'Api Docs - Auth',
        component: Authentication,
      },
      {
        path: 'collections',
        name: 'Api Docs - Collections',
        component: Lists,
      },
      {
        path: 'errors',
        name: 'Api Docs - Errors',
        component: Errors,
      },

      // endpoints
      { path: ':routePart+', name: "Api Docs - Endpoint", component: Endpoint}

    ]
  }
]

export default function router(state){

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });

  router.beforeEach((to, from) => {

    console.log(state.user);
    if(state.user === false || state.user === undefined){
      // location.href = apiBASE + 'auth/login';
    }

    state.commit('setCommandbarContextCommands', []);
  });

  return router;
}
