<template>

  <BaseModelStyle class="w-full" style="max-width: 400px;">
    <h2 class="text-2xl">Create a new collection</h2>
    <br>


    <div class="flex gap-2">

      <svg-icon type="mdi" :path="availableIcons[icon]" class="inline bg-white text-orchid-500 rounded-md" size="38" @click.stop="iconPickerOpen = !iconPickerOpen" />

      <!-- icon picker -->
      <div v-if="iconPickerOpen" class="fixed top-0 left-0 w-screen flex h-screen items-center">
        <div  class="mx-auto icon-selector gap-1 bg-white rounded-md p-5 shadow-2xl">
          <p class="text-black mb-2">Choose an Icon</p>
          <div class="grid grid-cols-10 gap-2" style="">
            <svg-icon type="mdi" v-for="[key, path] of Object.entries(availableIcons)" @click.stop="icon = key; iconPickerOpen = false" :path="path" class="inline bg-white text-black border-2 border-orchid-400 rounded-md" size="32" />
          </div>
        </div>
      </div>


      <input ref="input" v-model="name" placeholder="Collection Name" class="w-full rounded-md">
    </div>

    <br><br>
    <button @click="doImport" class="button bg-orchid-500 rounded-md p-2">Import</button>
  </BaseModelStyle>

</template>
<script>

import {Modal} from "jenesius-vue-modal";
import BaseModelStyle from "@/components/BaseModelStyle.vue";
import Dynamic_icon from "@/components/dynamic_icon.vue";
import {availableIcons} from "@/lib/icons";
import SvgIcon from "@jamescoyle/vue-icon";

export default {
  components: {BaseModelStyle, Dynamic_icon, SvgIcon},
  data: () => ({
    name: null,
    icon: 'mdiFolder',
    iconPickerOpen: false,
  }),
  mounted() {
    this.$refs.input.focus();
  },
  methods: {
    doImport() {
      this.$emit(Modal.EVENT_PROMPT, {
        name: this.name,
        icon: this.icon
      });
    },
  },
  computed: {
    availableIcons() {
      return availableIcons;
    },
  }
}
</script>