<template>
  <router-link :to="url">
    <div class="">
      <div class="items-center w-full">
        <img :src="thumbnail" class="bg-menu rounded-md object-cover w-full" :class="{ preview: preview}" />
      </div>
    </div>
  </router-link>
</template>

<script>

import InfiniteLoading from "v3-infinite-loading";
import {getFileIcon} from "@/lib/files";
import {mdiDownload, mdiFileImage} from "@mdi/js";
import SvgIcon from '@jamescoyle/vue-icon'
import {prettyPrintFileSize} from "@/lib/files";

export default {
  name: 'fileItemImage',
  components: {
    InfiniteLoading,
    SvgIcon
  },
  props: {
    file: Object,
    preview: Boolean,
  },
  computed: {
    filesize(){
      return prettyPrintFileSize(this.file.size);
    },
		thumbnail(){
			if(this.file?.urls?.thumbnail){
				return this.file?.urls?.thumbnail;
			}else{
				return this.file?.urls?.full;
			}
		},
    url(){
      return '/file/' + this.file.id;
    },
    icon() {
      const icon = getFileIcon(this.file.mime);
      if(icon === mdiFileImage){
        return false;
      }
      return icon;
    },
  }
}

</script>

<style>

</style>