<template>

  <BaseModelStyle>


    <div class="grid grid-cols-1">

      <div v-for="collection in collections" class="flex gap-2 justify-start mb-2">
        <input type="checkbox" style="width: 35px; height: 35px;" :checked="checked(collection.id)" @change="changeChecked(collection)"/>
        <span class="text-xl">{{collection.name}}</span>
      </div>

    </div>
		<button @click="save" class="interact">Close</button>



  </BaseModelStyle>

</template>
<script>
import {Modal} from "jenesius-vue-modal";
import BaseModelStyle from "@/components/BaseModelStyle.vue";

export default {
  components: {BaseModelStyle},
  props: {
    file: {
			type: Object,
			required: true,
		}
  },
  data: () => {
    return {
      collections: [],
      collectionEntries: []
    }
  },
  mounted(){

    this.$api.v2.get(`collection_items?item=${this.file.id}&limit=100`).then( res => {
      if(res.data){
        this.collectionEntries = res.data.data;
      }

      this.$api.v2.get(`collections?type=user_created`).then( res => {
        if(res.data){
          this.collections = res.data.data;
        }
      })

    })


  },
  methods: {

    async changeChecked(collection){
      const currentState = this.checked(collection.id);

      if(currentState){
        await this.$api.v2.delete(`collection_items/${currentState.id}`)
      }else{
        await this.$api.v2.post(`collection_items`, {
            test: true,
            collection: collection.id,
            item: this.file.id
        });
      }

      this.$api.v2.get(`collection_items?item=${this.file.id}&limit=100`).then( res => {
        if(res.data){
          this.collectionEntries = res.data.data;
        }
      })

    },

    checked(id){
      console.log(this.collectionEntries, this.collections, id);
      return this.collectionEntries.find( x => x.collection.id === id)
    },

		save(){
      this.$emit(Modal.EVENT_PROMPT, false);
		},

  }
}
</script>