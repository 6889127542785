<template>

  <h1 class="font-title text-2xl">Errors</h1>
  <Codeblock class="mb-5" url="Error format" :raw-text="JSON.stringify({ 'code': '401-10', 'message': 'You need to be authenticated to access this endpoint.' }, null, 2)"></Codeblock>

  <table class="min-w-full divide-y divide-black p-5 bg-black bg-opacity-25 rounded-md mb-5">
    <tr>
      <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">Code</th>
      <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">Realm</th>
      <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">Description</th>
    </tr>
    <tr v-for="error in errorMap()">
      <td><span class="arg">{{error.code}}</span></td>
      <td>{{ error.realm }}</td>
      <td>{{ error.description }}</td>
    </tr>
  </table>

</template>

<script>

const errorMap = [
  {
    code: "401-10",
    realm: "auth",
    description: "You need to be authenticated to access this endpoint."
  },
  {
    code: "401-11",
    realm: "auth",
    description: "You need to be authenticated to access this endpoint."
  }
]

import Codeblock from "@/components/layout/codeblock.vue";
import {apiBASE} from "@/lib/http";

export default {
  name: 'Api Documentation Errors',
  components: {
    Codeblock
  },
  mounted() {
    this.$store.commit('setTitle', 'Api Documentation')
  },
  methods: {
    errorMap() {
      return errorMap
    },
    apiBASE() {
      return apiBASE
    }

  },
  computed: {
  }
}
</script>
