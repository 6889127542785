<template>

  <div v-if="!loggedIn">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1>Profile</h1>
          <p>You need to be logged in to view this page.</p>
        </div>
      </div>
    </div>
  </div>

  <template v-else>

    <div class="justify-center flex gap-5">

      <router-link to="/settings/about" v-slot="{ isActive }">
        <button class="chip" :class="{active: isActive}">
          About
        </button>
      </router-link>
      <router-link to="/settings/billing" v-slot="{ isActive }">
        <button class="chip" :class="{active: isActive}">
          Billing
        </button>
      </router-link>
      <router-link to="/settings/api" v-slot="{ isActive }">
        <button class="chip" :class="{active: isActive}">
          API
        </button>
      </router-link>

    </div>

    <br><br>

    <router-view/>

  </template>

</template>

<script>

import TokenList from "@/components/data/tokens/tokenList.vue";
import svgIcon from "@jamescoyle/vue-icon";

export default {
  name: 'Profile',
  components: {
    svgIcon,
    TokenList
  },
  data() {
    return {
      payment_method: null,
      loading: true,
    }
  },
  mounted() {
    this.$store.commit('setTitle', 'My Profile')
  },
  methods: {
    subscribeToPremium(){
      location.href = `https://${process.env.VUE_APP_API_HOST}api/v1/subscription/create`;
    },
    manageSubscription(){
      location.href = `https://${process.env.VUE_APP_API_HOST}api/v1/subscription/portal`;
    },
    logout(){
      location.href = `https://${process.env.VUE_APP_API_HOST}auth/logout`;
    },
  },
  computed: {
    subscription(){
      return this.$store.state.user.subscription
    },
    loggedIn(){
      return !!this.$store.state.user
    },
    user(){
      return this.$store.state.user
    }
  }
}
</script>
