<template>

  <div class="grid grid-cols-4 gap-5">
    <div class="bg-black bg-opacity-25 rounded-md p-5">
      <h1 class="text-2xl font-bold text-white">Topics</h1>
      <router-link to="/api/docs/intro">Introduction</router-link><br>
      <router-link to="/api/docs/auth">Authentication</router-link><br>
      <router-link to="/api/docs/collections">Collections</router-link><br>
      <router-link to="/api/docs/errors">Errors</router-link><br>

      <h1 class="text-2xl font-bold text-white mt-5">Endpoints</h1>
      <entry :node="api.children.v2" :name="'v2'" :path="'api/docs'"></entry>
    </div>

    <div class="api-content col-span-3 bg-black bg-opacity-25 rounded-md p-5">
      <router-view></router-view>
    </div>

  </div>


</template>

<script>


import Entry from "@/components/api/entry.vue";

export default {
  name: 'Api Documentation',
  components: {Entry},
  mounted() {
    this.$store.commit('setTitle', 'Api Documentation')
  },
  methods: {

  },
  computed: {
    api() {
      return this.$store.state?.application?.application?.documentation?.api;
    }
  }
}
</script>
