<template>

  <div class=" align-middle justify-center h-full w-full">

    <h1 class="text-4xl text-center md:mt-10">
      Log in using
      <transition name="fade" mode="out-in">
        <div class="inline" :key="'provider-text-'+provider">
          {{ provider }}
        </div>
      </transition>
    </h1>

    <div class="flex gap-5 justify-center mt-5">
      <button class="" @mouseenter="doSet('Twitch')" @mouseleave="doSet()" @click="doLoginWith('twitch')"><svg-icon class="hover:text-gray-50 hover:scale-105 transition-transform" type="mdi" :path="mdiTwitch()" size="128"/></button>
      <button class="" @mouseenter="doSet('YouTube')" @mouseleave="doSet()" @click="doLoginWith('google')"><svg-icon class="hover:text-gray-50 hover:scale-105 transition-transform"  type="mdi" :path="mdiYoutube()" size="128"/></button>
      <button class="" @mouseenter="doSet('Discord')" @mouseleave="doSet()" @click="doLoginWith('discord')"><svg-icon class="hover:text-gray-50 hover:scale-105 transition-transform"  type="mdi" :path="mdiDiscord()" size="128"/></button>
    </div>
  </div>

</template>

<script>

import {mdiLockOutline, mdiTwitch, mdiTwitter, mdiYoutube} from "@mdi/js";
import {mdiGithub} from "@mdi/js";
import {mdiGoogle} from "@mdi/js";
import { startAuthentication } from '@simplewebauthn/browser';

import svgIcon from "@jamescoyle/vue-icon";
import {mdiDiscord} from "@/lib/icons";

export default {
  name: 'Login',
  components: {svgIcon},
  data: () => ({
    provider: 'your favorite platform',
  }),
  mounted() {
    this.$store.commit('setTitle', 'Login')
  },
  methods: {
    doSet(provider){
      if(!provider){ provider = 'your favorite platform'; }
      this.provider = provider;
    },
    mdiDiscord() {
      return mdiDiscord
    },
    mdiYoutube() {
      return mdiYoutube
    },
    mdiTwitch() {
      return mdiTwitch
    },
    mdiLockOutline() {
      return mdiLockOutline
    },
    doLoginWith(provider){
      return window.location = `https://${process.env.VUE_APP_API_HOST}auth/${provider}/login`;
    },
  }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0.5;
}
</style>