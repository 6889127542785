<template>

  <div :class="node._type" class="ml-2">
    <router-link  :to="url">
      <span>{{ name }}</span>
    </router-link>
    <div v-if="node._type === 'awoo::endpoint_group'">
      <entry v-for="[key, value] of Object.entries(node.children)" :key="key + name" :node="value" :name="key" :path="path + '/' + name"></entry>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Entry',
  props: {
    name: "",
    path: "",
    node: {
      type: Object,
      required: true,
    },
  },
  computed: {
    url() {
      return '/' + this.path + '/' + this.name;
    }
  }
}

</script>