<template>

  <h1 class="font-title text-3xl">{{ doc.path }}</h1>


  <!-- Methods -->
  <div v-for="method in Object.keys(doc.methods)" :key="`${method}:${doc.path}`" class="bg-black bg-opacity-25 rounded-md p-5 mb-5">
    <div v-if="doc.methods[method] && doc.methods[method].available !== false">
      <h2 class="font-title text-xl">{{ method.toUpperCase() }}</h2>

      <p class="arg">{{ doc.methods[method].method }} {{ doc.methods[method].path }}</p>

      <p v-if="doc.methods[method].description" class="mt-5 mb-5 bold">{{ doc.methods[method].description }}</p>

      <p v-if="doc.methods[method].requirements.auth" class="">This method is only accessible to authenticated users.</p>
      <p v-else class="">This method is publicly accessible.</p>

      <p v-if="doc.methods[method].requirements.auth && doc.methods[method].requirements.scopes.length > 0" class="flex gap-2 flex-wrap">
        <span>Additionally, the following scopes are required: </span>
          <span v-for="scope in doc.methods[method].requirements.scopes" :key="scope"
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 border-2 border-transparent">
            {{ scope }}
          </span>
      </p><br>

      <template v-if="doc.methods[method].filters">
        <h2 class="text-xl">Filters</h2>
        <template v-if="Object.keys(doc.methods[method].filters).length > 0">
          <table class="min-w-full divide-y divide-black p-5 bg-black bg-opacity-25 rounded-md mb-5">
            <tr>
              <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">Type</th>
              <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">Name</th>
              <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">Description</th>
              <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">Example</th>
            </tr>
            <tr v-for="filter in doc.methods[method].filters">
              <td><span class="arg">{{ filter.type.replace('Db', '') }}</span></td>
              <td>{{ filter.name }}</td>
              <td>{{ filter.description }}</td>
              <td>{{ filter.example }}</td>
            </tr>
          </table>
        </template>
        <p>All <router-link class="text-blue-300" to="/api/docs/collections">Collection</router-link> filters can be used.</p>
      </template>

      <template v-if="doc.methods[method].fields && Object.keys(doc.methods[method].fields).length > 0">
        <h2 class="text-xl">fields</h2>
        <table class="min-w-full divide-y divide-black p-5 bg-black bg-opacity-25 rounded-md mb-5">
          <tr>
            <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">Type</th>
            <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">Required</th>
            <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">Name</th>
            <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">Description</th>
            <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">Required Scopes</th>
            <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">Options</th>
            <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-0">Example</th>
          </tr>
          <tr v-for="filter in doc.methods[method].fields">
            <td><span class="arg">{{ filter.type.replace('Db', '') }}</span></td>
            <td>{{ filter.required ? 'Yes' : 'No' }}</td>
            <td>{{ filter.name }}</td>
            <td>{{ filter.description }}</td>
            <td>
              <span v-for="scope in filter.scopes" :key="scope"
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 border-2 border-transparent">
              {{ scope }}
              </span>
            </td>
            <td>{{ filter.allowed_values ? filter.allowed_values.join(' ') : '*' }}</td>
            <td>{{ filter.example }}</td>
          </tr>
        </table>
        <p v-if="method != 'compat uploader'">Fields must be passed a application/json  body.</p>
      </template>

      <template v-if="doc.example !== false && method === 'single'">
        <h2 class="font-title text-xl">Example Object</h2>
        <Codeblock class="mb-5" url="Example Object" :raw-text="JSON.stringify(doc.example, null, 2)"></Codeblock>
      </template>

    </div>
    <div v-else>
      <h2 class="font-title text-xl">{{ method.toUpperCase() }}</h2>
      <p>Method {{method}} not available on this endpoint.</p>
    </div>
  </div>

</template>

<script>


import Entry from "@/components/api/entry.vue";
import Codeblock from "@/components/layout/codeblock.vue";

export default {
  name: 'Api Documentation - Endpoint',
  components: {Codeblock, Entry},
  mounted() {
    this.$store.commit('setTitle', 'Api Documentation')
  },
  methods: {
    httpmethod(type){
      console.log(type);
      switch (type) {
        case 'single':
        case 'list':
          return 'GET';
        case 'create':
          return 'POST';
        case 'update':
          return 'PUT';
        case 'delete':
          return 'DELETE';
        case 'patch':
          return 'PATCH';
        default:
          return 'GET';
      }
    }
  },
  computed: {
    api() {
      return this.$store.state?.application?.application?.documentation;
    },
    doc(){
      let base = this.api?.api;
      for(const part of this.$route.params.routePart){
          base = base.children[part];
      }

      return base;
    },
    path(){
      return this.$route.params.routePart.join('/');
    }
  }
}
</script>