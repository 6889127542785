<template>

  <div class="grid grid-cols-1 gap-5">
    <div class="rounded-md border-white border-2 p-5">
      <h1 class="text-xl font-title italic font-bold">Billing</h1>

      <hr>
      <br>

			<p>
				For now, Awoo.Industries is free to use. In the future this will change.
			</p>

		</div>

	</div>


</template>

<script>

export default {
  name: 'UserSettingsBilling',
  components: {},
  data() {
  },
  mounted() {
    this.$store.commit('setTitle', 'About')
  },
  methods: {
    logout(){
      location.href = `https://${process.env.VUE_APP_API_HOST}auth/logout`;
    },
  },
  computed: {
    loggedIn(){
      return !!this.$store.state.user
    },
    user(){
      return this.$store.state.user
    }
  }
}
</script>
