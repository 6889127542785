<template>

  <div class="grid grid-cols-1 md:grid-cols-2 gap-5">

    <div class="col-span-full">
      <div class="rounded-md border-white border-2 p-5">
        <div class="">
          <h1 class="text-xl font-title italic font-bold">Access Tokens</h1>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">Allow third-party apps to use your account via the <router-link to="/api/docs">Api</router-link>.</p><br>
        </div>

        <hr>
        <br>

        <token-list/>
      </div>
    </div>

  </div>


</template>

<script>

import TokenList from "@/components/data/tokens/tokenList.vue";

export default {
  name: 'UserSettingsApi',
  components: {TokenList},
  data() {
  },
  mounted() {
    this.$store.commit('setTitle', 'About')
  },
  methods: {
    logout(){
      location.href = `https://${process.env.VUE_APP_API_HOST}auth/logout`;
    },
  },
  computed: {
    loggedIn(){
      return !!this.$store.state.user
    },
    user(){
      return this.$store.state.user
    }
  }
}
</script>
