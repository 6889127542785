<template>

  <div class="">
    <dd class="text-sm">

      <div v-if="newToken" class="bg-black bg-opacity-25 p-2 m-2 bg-menu rounded-md">
        <p>Your token "{{newToken.name}}" was created! Below is the secret, please make sure to save it somewhere secure as it wont be shown here again.</p>
        <br><br><b>{{ newToken.secret }}</b><br><br>
        <button class="chip mr-2" @click="copyToken(newToken.secret)">Copy token</button>
        <button class="chip" v-if="newToken.scopes.includes('file:write')" @click="createDownloadConfig(newToken)">download ShareX Config</button>
      </div>

      <div class="bg-black bg-opacity-25 p-2 m-2 bg-menu rounded-md grid grid-cols-2" v-for="token in tokens"
           :key="token.id">
        <div>
          <h1 class="block bold text-xl"><b>{{ token.name }}</b></h1>
          <p class="">{{ token.secret }}</p>
          <p class="">Created On: {{ new Date(token.createdon).toLocaleString() }}</p>
          <p class="">Last used: {{ timeAgo(token.lastUsed) }}</p>
          <p class="flex gap-2 p-2 flex-wrap">
              <span v-for="scope in token.scopes" :key="scope"
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 border-2 border-transparent" :class=" {'border-red': dangerScopes[scope]}">
                {{ scope }}
              </span>
          </p>
        </div>
        <div class="flex gap-2 justify-end flex">
          <!--            <a @click.prevent="copyToken(token)" class="block p-2 rounded bg-gray-700 bg-opacity-25 hover:bg-opacity-80 text-white cursor-pointer"><svg-icon type="mdi" :path="mdiClipboard" size="36"/></a>-->
          <!--				            <a @click.prevent="createDownloadConfig(token)" class="block p-2 rounded bg-gray-700 bg-opacity-25 hover:bg-opacity-80 text-white cursor-pointer"><svg-icon type="mdi" :path="mdiDownloadBoxOutline" size="36"/></a>-->
          <a @click.prevent="deleteToken(token)"
             class="min-h-0 block p-2 rounded bg-red-900 bg-opacity-25 hover:bg-opacity-80 text-white cursor-pointer">
            <svg-icon type="mdi" :path="mdiTrashCan()" size="36"/>
          </a>
        </div>
      </div>

      <button @click="createToken()" type="button"
              class="relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
        Create token
      </button>
    </dd>
  </div>

  <InfiniteLoading @infinite="load"/>
</template>

<script>

import InfiniteLoading from "v3-infinite-loading";
import {apiURL} from "@/lib/http";
import TimeAgo from "javascript-time-ago";
import en from 'javascript-time-ago/locale/en';

import svgIcon from "@jamescoyle/vue-icon";
import {mdiClipboard, mdiDownloadBoxOutline, mdiTrashCan} from "@mdi/js";
import {promptModal} from "jenesius-vue-modal";
import CreateToken, {dangerScopes} from "@/components/modals/CreateToken.vue";

TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US')

export default {
  name: 'tokenList',
  components: {
    InfiniteLoading,
    svgIcon,
  },
  data() {
    return {
      tokens: [],
      page: 0,
      perPage: 25,
      newToken: null,
    };
  },
  mounted() {
  },
  methods: {
    mdiTrashCan() {
      return mdiTrashCan
    },

    copyToken(token){
      navigator.clipboard.writeText(token);
      this.$notify({
        group: 'notify',
        title: 'Copied Token',
        text: 'The token has been copied to your clipboard.',
        type: 'success'
      });
    },

    timeAgo(date){

      if(date == null){
        return "Never"
      }

      return timeAgo.format(new Date(date))
    },

    createDownloadConfig(token){

      const config = {
        "Version": "14.0.1",
        "DestinationType": "ImageUploader, TextUploader, FileUploader",
        "RequestMethod": "POST",
        "RequestURL": apiURL + "files",
        "Headers": {
          "Authorization": `Token ${token.secret}`
        },
        "Body": "MultipartFormData",
        "FileFormName": "file",
        "URL": "{json:urls.short}",
        "ErrorMessage": "{response}"
      };

      const blob = new Blob([JSON.stringify(config, null, 2)], {type : 'application/json'});
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = `awoo.download.${token.name}.sxcu`;
      a.href=url;
      a.click();
      window.URL.revokeObjectURL(url);

    },

    createLinkConfig(token){

      const config = {
        "Version": "14.0.1",
        "DestinationType": "URLShortener",
        "RequestMethod": "POST",
        "RequestURL": "https://awoo.to/api/v2/shortener",
        "Parameters": {
          "url": "{input}"
        },
        "Headers": {
          "Authorization": `Token ${token.id}`
        },
        "URL": "{json:url}",
        "ErrorMessage": "{response}"
      }

      const blob = new Blob([JSON.stringify(config, null, 2)], {type : 'application/json'});
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = `awoo.to.${token.id.substr(0,20)}.sxcu`;
      a.href=url;
      a.click();
      window.URL.revokeObjectURL(url);
    },

    async deleteToken(token){
      let response = await this.$api.v2.delete(`/tokens/${token.id}`);
      location.reload();
    },

    async createToken(){
			const modal = await promptModal(CreateToken);
      this.newToken = modal;
    },

    async load($state){

      try {

        const response = await this.$api.v2.get(`/tokens?offset=${this.page * this.perPage}&limit=${this.perPage}`);

        if(response.status === 204){
          return $state.complete();
        }

        if(response.status !== 200) return $state.error();
        if(!response.data || !response.data.data) return $state.error();

        for(let i = 0; i < response.data.data.length; i++){
          // keep only last 10 characters
          response.data.data[i].secret = response.data.data[i].secret.substr(response.data.data[i].secret.length - 10);
        }

        this.tokens.push(...response.data.data);

        if (response.data.data.length < 10) {
          return $state.complete();
        }

        $state.loaded();
        this.page++;

      } catch (error) {
        $state.error();
      }
    }

  },
  computed: {
    mdiClipboard(){
      return mdiClipboard
    },
    mdiDownloadBoxOutline(){
      return mdiDownloadBoxOutline
    },
    user() {
      return this.$store.state.user
    },
    dangerScopes() {
      return dangerScopes;
    }
  }
}

</script>

<style>

</style>