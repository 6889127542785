<template>

  <BaseModelStyle>
    <h2 class="text-2xl">Edit File</h2><br>

<!--		<input v-model="file.filename" type="text" placeholder="File Name" class="w-full rounded-md my-2"><br>-->

		<label for="public">Make file public?</label>
		<input v-model="file.public" name="public" type="checkbox" class="w-full rounded-md my-2"><br>

		<button @click="save" class="interact">Save Changes</button>
		<button @click="deleteFile" class="interact border-red-400">Delete File</button>



  </BaseModelStyle>

</template>
<script>
import {Modal} from "jenesius-vue-modal";
import BaseModelStyle from "@/components/BaseModelStyle.vue";

export default {
  components: {BaseModelStyle},
  props: {
    file: {
			type: Object,
			required: true,
		}
  },
  methods: {

		save(){

			this.$api.v2.put(`/files/${this.file.id}`, {
				public: this.file.public
			}).then(response => {
				this.$emit(Modal.EVENT_PROMPT, false);
				this.$router.go();
			})

		},

		deleteFile(){
			if(confirm('Are you sure you want to delete this file? This cannot be undone.')){
				this.$api.v2.delete(`/files/${this.file.id}`).then(response => {
					this.$router.push('/files');
					this.$emit(Modal.EVENT_PROMPT, false);
				}).catch(error => {
					this.$notify({ type: 'error', text: 'Failed to delete file'});
				});
			}


		}

  }
}
</script>