import axios from "axios";
export const apiBASE = `https://${process.env.VUE_APP_API_HOST}`;

export const apiV1 = `${apiBASE}api/v1/`;
export const apiV2 = `${apiBASE}api/v2/`;

export const apiURL = apiV1;

export const V1 = axios.create({
    baseURL: apiV1,
    timeout: 60000,
    withCredentials: true,
    headers: {'X-Application': 'Awoo.industries/23.5.0'}
});

export const V2 = axios.create({
    baseURL: apiV2,
    timeout: 30000,
    withCredentials: true,
    headers: {'X-Application': 'Awoo.industries/23.5.0'}
});
export const Auth = axios.create({
    baseURL: `${apiBASE}auth/`,
    timeout: 30000,
    withCredentials: true,
    headers: {'X-Application': 'Awoo.industries/23.5.0'}
});