<template>

  <div class="" v-if="link">

    <!-- Link Info -->
    <div class="grid grid-cols-1 md:grid-cols-3">
      <div class="flex flex-col bg-black bg-opacity-25 p-2 m-2 bg-menu rounded-md">
        <div class="text-2xl font-bold">https://awoo.to/{{link.id}}</div>
        <div class="text-sm">Your Link</div>
      </div>
      <div class="flex flex-col bg-black bg-opacity-25 p-2 m-2 bg-menu rounded-md">
        <div class="text-2xl font-bold">{{link.url}}</div>
        <div class="text-sm">Points to</div>
      </div>
      <div class="flex flex-col bg-black bg-opacity-25 p-2 m-2 bg-menu rounded-md">
        <div class="text-2xl font-bold">{{views}}</div>
        <div class="text-sm">Total Clicks</div>
      </div>
    </div>
    <br>
    <br>

    <div class="flex items-center content-center justify-center">
      <div class="max-w-4xl flex-grow bg-white rounded-md text-black p-5">
      <!-- Chart Options -->
      <div class="inline-block">

        <div class="grow flex justify-between items-center">

          <select v-model="chart_type" class="bg-transparent mr-20 text-black [&>*]:text-black">
            <option value="year">Year</option>
            <option value="month">Month</option>
            <option value="day">Day</option>
          </select>

          <select v-model="chart_filter.year" class="bg-transparent text-black [&>*]:text-black">
            <option v-for="year of filterableYears" :value="year">{{ year }}</option>
          </select>

          <template v-if="['month', 'day'].includes(chart_type)">
            /
            <select v-model="chart_filter.month" class="bg-transparent text-black [&>*]:text-black">
              <option v-for="month of filterableMonths" :value="month">{{ Intl.DateTimeFormat('en', { month: 'long' }).format(new Date(`1970-${month}-1`)) }}</option>
            </select>
          </template>

          <template v-if="['day'].includes(chart_type)">
            /
            <select v-model="chart_filter.day" class="bg-transparent text-black [&>*]:text-black">
              <option v-for="day of filterableDays" :value="day">{{ day }}</option>
            </select>
          </template>

        </div>

      </div>

      <Bar
          id="my-chart-id"
          :options="{responsive: true}"
          :data="chart_data"
      />

      </div>
    </div>

  </div>

</template>

<script>

import shortenForm from '@/components/data/shorturl/shortenForm.vue'
import shortList from "@/components/data/shorturl/shortList.vue";
import {getDay, getHour, getMonth, getYear} from "@/lib/date";
import svgIcon from "@jamescoyle/vue-icon";

import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

const months = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


export default {
  name: 'ShortDetail',
  components: {
    svgIcon,
    shortenForm,
    shortList,
    Bar
  },
  props: {
    id: String,
  },
  data() {
    return {
      chart_type: 'month',
      chart_filter: {
        year: new Date().getFullYear(),
        month: String(new Date().getMonth() + 1).padStart(2, '0'),
        day: new Date().getDate(),
      },
      chart_data: {
        labels: [],
        datasets: []
      },
      loading: true,
      link: null,
    };
  },
  mounted() {
    this.$store.commit('setTitle', 'Loading...');
    this.$http.get(`/shortener/${this.id}`).then(response => {
      this.link = response.data;
      console.log(this.link.stats);
      this.$store.commit('setTitle', 'awoo.to/' + response.data.id);

      this.updateChartData();
      this.loading = false;
    }).catch(error => {
      this.loading = false;
    });
  },
  watch: {
    chart_type(old, newer){

      if(newer === 'year') this.chart_filter.month = this.filterableMonths[0];
      if(newer === 'year' || newer === 'month') this.chart_filter.day = this.filterableDays[0];

      this.updateChartData();
    },
    chart_filter: {
      handler(){
        this.updateChartData();
      },
      deep: true
    }
  },
  methods: {
    updateChartData(){

      // this is all extremely stupid and i hate it
      // but it works so i'm not gonna touch it again
      // unless i have to

      const lables = [];
      const data = [];

      switch(this.chart_type){
        case "year":
          for(let i = 0; i < 12; i++){
            const month = String(i).padStart(2, '0');
            lables.push(months[i]);
            data.push(this.link.stats?.[this.chart_filter.year]?.[month]?.['*'] || 0);
          }
          break;

        case "month":
            for(let i = 1; i < 32; i++){
              const month = String(this.chart_filter.month).padStart(2, '0');
              const day = String(i).padStart(2, '0');
              lables.push(new Date(`1970-${month}-${day}`).toLocaleDateString('en-US', { weekday: 'short', day: "2-digit" }));
              console.log('month', this.chart_filter.year, month, day)
              data.push(this.link.stats?.[this.chart_filter.year]?.[month]?.[day]?.['*'] || 0);
            }
          break;
        case "day":

            for(let i = 0; i < 24; i++){
              lables.push(`${i}:00`);
              data.push(this.link.stats?.[this.chart_filter.year]?.[this.chart_filter.month]?.[this.chart_filter.day]?.[i] || 0);
            }
            break;
      }

      this.chart_data = {
        labels: lables,
        datasets: [
            {
              data: data,
              label: 'Clicks',
              borderColor: '#36A2EB',
              backgroundColor: '#9BD0F5',
            }
        ]
      };

    }
  },
  computed:{

    filterableYears(){
      return Object.keys(this.link.stats || {}).sort((a,b) => b - a).filter(year => year !== '*');
    },
    filterableMonths(){
      return Object.keys(this.link.stats?.[this.chart_filter.year] || {}).sort((a,b) => b - a).filter(month => month !== '*');
    },
    filterableDays(){
      return Object.keys(this.link.stats?.[this.chart_filter.year]?.[this.chart_filter.month] || {}).sort((a,b) => b - a).filter(day => day !== '*');
    },

    viewsCurrentHour(){
      return this.link.stats?.[getYear()]?.[getMonth()]?.[getDay()]?.[getHour()] || 0;
    },
    viewsCurrentDay(){
      return this.link.stats?.[getYear()]?.[getMonth()]?.[getDay()]?.['*'] || 0;
    },
    viewsCurrentMonth(){
      return this.link.stats?.[getYear()]?.[getMonth()]?.['*'] || 0;
    },
    viewsCurrentYear(){
      return this.link.stats?.[getYear()]?.['*'] || 0;
    },
    views(){
      return this.link.stats?.['*'] || 0;
    }
  }
}
</script>

<style scoped lang="less">
select {
  margin-right: 5px;
  margin-left: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
</style>