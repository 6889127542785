import {createStore} from 'vuex'
import {V1} from '@/lib/http';

export default createStore({
    state: {
        title: '',
        titleVisible: true,

        user: null,
        application: null,

        socket: null,
        jobs: {},
        commandbar: {
            active: false,
            contextual_commands: []
        }
    },
    getters: {
        title: state => state.title,
        titleVisible: state => state.titleVisible,
        user: state => state.user,
        application: state => state.application,
        socket: state => state.socket,
        socketConnected: state => state.socket?.connected,
        getJobs: state => state.jobs.filter(job => job.progress < 100),
        getJobCount: state => Object.keys(state.jobs).length,
    },
    mutations: {
        setTitle: (state, title) => {
            state.title = title
            state.titleVisible = title.length > 0;
            document.querySelector('title').innerHTML = `Awoo.industries - ${title}`
        },
        setUser(state, user) {
            state.user = user
        },
        setApplication(state, data) {
            state.application = data
        },
        setJobs(state, data) {
            state.jobs = data
        },
        setCommandbar(state, data) {
            state.commandbar.active = !!data
        },
        setCommandbarContextCommands(state, data) {
            state.commandbar.contextual_commands = data
        }
    },
    actions: {
        checkAuth(state) {
            V1.get('/users/@me').then((response) => {
                state.commit('setUser', response.data)
            }).catch((error) => {
                console.warn(error);
                state.commit('setUser', false)
            });
        },
        getApplicationStatus(state) {
            V1.get('../../').then((response) => {
                state.commit('setApplication', response.data)
            }).catch((error) => {
                console.warn(error);
                state.commit('setApplication', false)
            });
        },
        connectSocket(state) {
            console.log('🔗', '[SOCKET]', `Connecting to`, `wss://${process.env.VUE_APP_API_HOST}`);
            state.socket = new WebSocket(`wss://${process.env.VUE_APP_API_HOST}`);

            state.socket.onopen = function (event) {
                console.log('✅', '[SOCKET]', `Connected!`);
                backoff = 1000;
            }

            state.socket.onclose = function (event) {
                console.log('❌', '[SOCKET]', `Disconnected!`, `Reconnecting in ${backoff}ms...`);
                setTimeout(() => {
                    backoff = Math.min(backoff + 1000, 10000);
                    state.dispatch('connectSocket')
                }, backoff);
            }

            state.socket.onmessage = function (event) {
                const data = JSON.parse(event.data);
                console.log('📨', '[SOCKET]', `Message received:`, data);
                if (data.op in opcodes) {
                    opcodes[data.op](state, data.data);
                } else {
                    console.log('❌', '[SOCKET]', `Opcode ${data.op} not implemented!`);
                }
            }
        }
    },
    modules: {}
})

let backoff = 1000;
const opcodes = {
    0: null, // Client sent event.
    1: (state, data) => {
        console.log('🔑', '[SOCKET]', `Authenticated with user ${data.user.id}!`);
        setTimeout(() => {
            state.dispatch('checkAuth');
        }, 2000);
    },
    10: (state, data) => {
        console.log('🎁', '[SOCKET]', `Received dispatch "${data.evt}" for "${data.type}/${data.obj.id}"!`);

        switch(data.type){
            case "jobs": {
                if(data.obj.type !== 'import_file') return;
                if(data.obj.progress === 100){
                    delete state.state.jobs[data.obj.id];
                }else{
                    state.state.jobs[data.obj.id] = data.obj;
                }
                break;
            }
            default: console.log('❌', '[SOCKET]', `Unknown dispatch object type "${data.type}"!`);
        }


    }
}