<template>

  <div class="interact flex justify-between items-center text-white hover:text-black" @click="$refs.search.focus()">
    <svg-icon type="mdi" :path="mdiMagnify()" class="inline" />
    <input type="search" class="bg-white rounded-lg px-2 rounded-md bg-transparent grow text-white hover:text-black p-2" v-model="search" ref="search" />

    <select v-model="selected_mime" @click.stop class="p-2 bg-transparent [&>*]:text-black">
      <option value="">All Types</option>
      <optgroup label="Images">
        <option value="image/*">Any Image</option>
        <option value="image/jpeg">JPEG</option>
        <option value="image/png">PNG</option>
        <option value="image/gif">GIF</option>
        <option value="image/svg+xml">SVG</option>
      </optgroup>
      <optgroup label="Videos">
        <option value="video/*">Any Video</option>
        <option value="video/mp4">MP4</option>
        <option value="video/mov">MOV</option>
        <option value="video/webm">WebM</option>
      </optgroup>
      <optgroup label="Audio">
        <option value="audio/*">Any Audio</option>
        <option value="audio/mpeg">MP3</option>
        <option value="audio/ogg">Ogg</option>
        <option value="audio/wav">WAV</option>
      </optgroup>
      <optgroup label="Other">
        <option value="application/pdf">PDF</option>
        <option value="application/zip">Archive</option>
        <option value="application/octet-stream">Disk Image</option>
      </optgroup>
    </select>

    <div class="flex gap-2 ml-5">
      <svg-icon type="mdi" :path="mdiViewDashboard()" class="inline bg-white bg-opacity-10 rounded-full p-1" :class="{ 'bg-opacity-100 text-black': display_mode === 'mason'}" @click.stop="setListDisplayMode('mason')"/>
      <svg-icon type="mdi" :path="mdiViewGrid()" class="inline bg-white bg-opacity-10 rounded-full p-1" :class="{ 'bg-opacity-100 text-black': display_mode === 'grid'}" @click.stop="setListDisplayMode('grid')"/>
      <svg-icon type="mdi" :path="mdiViewList()" class="inline bg-white bg-opacity-10 rounded-full p-1" :class="{ 'bg-opacity-100 text-black': display_mode === 'list'}" @click.stop="setListDisplayMode('list')"/>
    </div>

  </div>

  <div v-if="scheduled_for_deletion" class="bg-red-500 text-white p-2 rounded-md text-center mt-2">
    <svg-icon type="mdi" :path="mdiAlertCircleOutline()" class="inline mr-2" />
    <span>Files in this view will be permanently deleted after 30 days.</span>
  </div>

  <grid ref="filegrid" endpoint="files" :filters="filters" :mode="gridMode" :perPage="10">
    <template #items="props" v-if="['list', 'grid'].includes(display_mode)">
      <div v-for="file in props.list" :key="file.id">
        <file-list-item class="result" :file="file" :display="display_mode"/>
      </div>
    </template>
    <template #items="props" v-else>
      <div>
        <div v-for="file in props.list.filter((_, index) => index % 5 === 0)" :key="file.id">
          <file-list-item class="result" :file="file" :display="display_mode"/>
        </div>
      </div>
      <div>
        <div v-for="file in props.list.filter((_, index) => index % 5 === 1)" :key="file.id">
          <file-list-item class="result" :file="file" :display="display_mode"/>
        </div>
      </div>
      <div>
        <div v-for="file in props.list.filter((_, index) => index % 5 === 2)" :key="file.id">
          <file-list-item class="result" :file="file" :display="display_mode"/>
        </div>
      </div>
      <div>
        <div v-for="file in props.list.filter((_, index) => index % 5 === 3)" :key="file.id">
          <file-list-item class="result" :file="file" :display="display_mode"/>
        </div>
      </div>
      <div>
        <div v-for="file in props.list.filter((_, index) => index % 5 === 4)" :key="file.id">
          <file-list-item class="result" :file="file" :display="display_mode"/>
        </div>
      </div>
    </template>
  </grid>
</template>

<script>

import Grid from "@/components/grid/Grid.vue";
import fileListItem from "@/components/data/files/fileListItem.vue";
import {
  mdiAlertCircleOutline,
  mdiMagnify,
  mdiSearchWeb, mdiViewDashboard, mdiViewGallery,
  mdiViewGrid,
  mdiViewList
} from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";

export default {
  name: 'FileGrid',
  components: {
    Grid,
    fileListItem,
    SvgIcon
  },
  props: {
    public: null,
    scheduled_for_deletion: false,
    display_by_date: false,
  },
  data() {
    return {

      // Filters
      selected_group: null,
      search: "",
      selected_mime: "",

      // Display
      display_mode: 'list',

    };
  },

  mounted() {
    this.$store.commit('setTitle', 'My Files');
    this.display_mode = window.localStorage.getItem(`listmode.${this.$route.path}`) || window.localStorage.getItem(`listmode.default`) || 'mason';

    if(this.$route.params.id){
      this.selected_group = this.$route.params.id;
    }

  },
  methods: {
    mdiViewDashboard() {
      return mdiViewDashboard
    },
    mdiViewGallery() {
      return mdiViewGallery
    },
    mdiAlertCircleOutline() {
      return mdiAlertCircleOutline
    },
    mdiViewList() {
      return mdiViewList
    },
    mdiViewGrid() {
      return mdiViewGrid
    },
    mdiMagnify() {
      return mdiMagnify
    },
    mdiSearchWeb() {
      return mdiSearchWeb
    },
    reload(){
      this.$refs.filegrid.reset();
    },
    setListDisplayMode(mode){
      window.localStorage.setItem(`listmode.${this.$route.path}`, mode);
      window.localStorage.setItem(`listmode.default`, mode);
      this.display_mode = mode;
    }
  },
  computed:{

    gridMode(){

      switch(this.display_mode){
        case "list": return 'grid grid-flow-row-dense grid-cols-1';
        case "grid": return 'grid grid-flow-row-dense grid-cols-3 gap-2';
        case "mason": default: return 'grid grid-flow-row-dense grid-cols-5 gap-2';
      }
    },

    filters(){
      let filters = '';
      if(this.search) filters += `&search=${encodeURIComponent(this.search)}`;
      if(this.scheduled_for_deletion) filters += `&scheduled_for_deletion=1`;
      if(this.public != null) filters += `&public=${ this.public ? '1' : '0' }`;
      if(this.selected_group) filters += `&collection=${this.selected_group}`;
      if(this.selected_mime) filters += `&type=${this.selected_mime}`;
      return filters;
    },
    user(){
      return this.$store.state.user
    },
  }
}
</script>
