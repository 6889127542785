<template>
    <td>{{obj.url}}</td>
    <td>https://awoo.to/{{obj.id}}</td>
    <td>{{views}}</td>
</template>
<script>

import {mdiArrowRightBold} from "@mdi/js";
import svgIcon from "@jamescoyle/vue-icon";
import getFormattedNumber from "@/lib/ui";

export default {
  props: {
    obj: Object
  },
  components: {
    svgIcon
  },
  computed: {
    mdiArrowRightBold() {
      return mdiArrowRightBold
    },
    url() {
      return 'https://awoo.to/' + this.obj.id;
    },
    views() {
      return getFormattedNumber(this?.obj?.stats['*'])
    }
  }
}
</script>
<style scoped>

</style>
