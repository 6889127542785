<template class="text-center">

  <div v-if="loading">
    Loading...
  </div>

  <template v-if="file">

    <div class="flex justify-around mb-2">
      <h2 class="text-2xl">{{ file.filename }}</h2>
      <div class="buttons">
        <div class="flex justify-center gap-2">
          <div class="interact" @click="downloadFile">
            <svg-icon :path="mdiDownload" type="mdi" class="inline" />
            Download
          </div>

          <div class="interact" @click="copyDownloadURL" v-if="file && file.public && file.short">
            <svg-icon :path="mdiShare" type="mdi" class="inline" />
            Copy public link
          </div>


          <div class="interact" @click="editFile" v-if="file && file.user.id === user.id">
            <svg-icon :path="mdiCog" type="mdi" class="inline" />
            Manage
          </div>


          <div class="interact" @click="editCollections" v-if="file && file.user.id === user.id">
            <svg-icon :path="mdiFolder()" type="mdi" class="inline" />
            Collections
          </div>

        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 justify-center w-full">

      <div class="aspect-video justify-center m-auto">

        <!-- Image -->
        <template v-if="image">
          <div>
            <img :src="imageURL" class="bg-menu rounded-2xl" />
          </div>
        </template>

        <!-- Video -->
        <video v-else-if="video" controls="controls" class="rounded-md">
          <source :src="imageURL" :type="file.mime">
          <source :src="imageURL" type="video/mp4">
          Your browser does not support the video tag.
        </video>

        <!-- Audio -->
        <div v-else-if="audio" class="p-5 m-25 ">

          <template v-if="file.urls?.waveform">
            <img :src="file.urls.waveform" class="waveform-preview" @click="clickWaveform" />
            <img :src="file.urls.waveform" class="waveform-preview progress" ref="waveform" />
          </template>

          <div class="flex justify-center gap-10">
            <div class="bg-black bg-opacity-20 p-3 rounded-md">
              <svg-icon type="mdi" :path="iconPlay" @click="toggleAudio" />
            </div>
            <div class="bg-black bg-opacity-20 p-3 rounded-md">
              <input style="width: 25vw; max-width: 150px;" class="" type="range" id="volume" name="volume" min="0" max="100" v-model="volume">
            </div>
          </div>


          <audio @timeupdate='onPlaybackStatus' ref="audio" controls v-show="!file.urls?.waveform">
            <source :src="imageURL" :type="file.mime">
            <source :src="imageURL" type="audio/mpeg">
            Your browser does not support the audio element.
          </audio>
        </div>

        <!-- Other -->
        <div v-else-if="file" class="p-5 m-25">
          No preview is available for this file.
        </div>
      </div>

    </div>

    <p class="p-2 mt-2 bg-white bg-opacity-20 rounded-md" v-if="file.metadata.description">
      {{ file.metadata.description }}
    </p>

    <div class="flex gap-2 mt-2">
      <div v-if="file.metadata.tags" class="chip bg-white text-black rounded-lg inline p-2" v-for="item in file.metadata.tags.split(',')">
        {{item}}
      </div>
    </div>


  </template>



</template>

<script>

import fileList from '@/components/data/files/fileList.vue'
import {getFileIcon} from "@/lib/files";
import svgIcon from '@jamescoyle/vue-icon'
import UserProfile from "@/components/ui/user.vue";

import {mdiDownload, mdiPlay, mdiPause, mdiShare, mdiDelete, mdiCog, mdiFolder} from "@mdi/js";
import Responsive from "@/App.vue";
import {promptModal} from "jenesius-vue-modal";
import ImportFromURL from "@/components/modals/ImportFromURL.vue";
import DownloadChooser from "@/components/modals/downloadChooser.vue";
import EditFile from "@/components/modals/editFile.vue";
import EditCollections from "@/components/modals/editCollections.vue";

export default {
  name: 'FileView',
  components: {
    Responsive,
    UserProfile,
    fileList,
    svgIcon
  },
  props: {
    id: String,
  },
  data() {
    return {

      mdiDownload: mdiDownload,
      mdiPlay: mdiPlay,
      mdiPause: mdiPause,
      mdiShare: mdiShare,
      mdiDelete: mdiDelete,
			mdiCog: mdiCog,

      loading: true,
      file: null,
      isPlaying: false,
      volume: null,
    };
  },
  unmounted() {
  },
  mounted() {


    this.$store.commit('setTitle', 'Loading...')
    this.$api.v2.get(`/files/${this.id}`).then(response => {
      this.file = response.data;
      let title = response.data.filename.split('/');
      this.$store.commit('setTitle', title[title.length - 1]);
      this.loading = false;
      this.$nextTick(() => {
        this.volume = window.localStorage.getItem('volume') || 50;

        this.$store.commit('setCommandbarContextCommands', [
          {
            name: 'Download',
            icon: mdiDownload,
            action: () => {
              this.downloadFile(this.file.urls.full);
            }
          },
          {
            name: 'Share URL',
            icon: mdiShare,
            action: this.copyDownloadURL
          },
          {
            name: 'Delete File',
            icon: mdiDelete,
            action: this.deleteFile
          },
        ])

      })
    }).catch(error => {
      this.loading = false;
    });
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    is_author() {
      return this.user?.id === this.file?.user?.id;
    },
    icon() {
      return getFileIcon(this?.file?.mime);
    },
    image() {
      return this?.file?.mime?.startsWith('image/');
    },
    imageURL(){
      return this.file.urls.full;
    },
    video() {
      return this?.file?.mime?.startsWith('video/');
    },
    audio() {
      return this?.file?.mime?.startsWith('audio/');
    },
    pdf() {
      return this?.file?.mime?.startsWith('application/pdf');
    },
  },
  methods: {
    mdiFolder() {
      return mdiFolder
    },
    clickWaveform(e) {

      const xPosition = e.clientX - e.target.getBoundingClientRect().left;
      const percent = (xPosition / e.target.width) * 100;

      this.$refs.audio.play();
      this.$nextTick(() => {
        this.$refs.audio.currentTime = (this.$refs.audio.duration * (percent / 100));
        this.$refs.audio.play();
      });


    },
    onPlaybackStatus(e){
      const player = e.target;
      const progress = (player.currentTime / player.duration) * 100;
      const waveform = this.$refs.waveform;
      waveform.style.clipPath = `polygon(0 0, ${progress}% 0, ${progress}% 100%, 0% 100%)`;

      this.isPlaying = !player.paused;

    },
    toggleAudio(){
      if(this.$refs.audio.paused){
        this.$refs.audio.play();
      } else {
        this.$refs.audio.pause();
      }
    },
		copyDownloadURL(){
			navigator.clipboard.writeText(this.file.urls.short);
			this.$notify({ type: 'info', text: 'Copied link to clipboard'});
		},
    async editFile() {
      const url = await promptModal(EditFile, {
        file: this.file,
      });
    },
    async editCollections() {
      const url = await promptModal(EditCollections, {
        file: this.file,
      });
    },
    async downloadFile(url) {
      if(!url){
        url = await promptModal(DownloadChooser, {
          urls: this.file.urls,
          filename: this.file.filename
        });
      }

      if(!url) return;

      const link = document.createElement("a");
      link.href = this.file.urls[url];
      link.download = this.file.filename;
      link.click();
    },
  },
  watch: {
    volume: function (val) {
      if(this.$refs.audio){
        this.$refs.audio.volume = val / 100;
      }
      window.localStorage.setItem('volume', val);
    }
  }
}
</script>

<style>
  .bg-dimmed {
    background-color: rgba(0, 0, 0, 0);
  }
</style>