<template>

  <h1 class="font-title text-2xl">Authentication</h1>
  <p>Authentication is done via tokens.
    For now the only way to obtain a token is to create it in the dashboard under Profile > Api > Tokens.
  <br>
  Tokens should be sent in the header of the request as `Authorization: Token $token` where `$token` is the token you obtained.
  </p>

  <h2 class="font-title text-xl mt-5">Obtaining the current user/token</h2>
  <p>Use the following endpoint to obtain the current user and token:</p>
  <Codeblock method="get" :url="`${apiBASE()}`"></Codeblock><br>

  <h2 class="font-title text-xl mt-5">Token Scopes</h2>
  <p>All tokens have a set of scopes they are allowed to use. Whenever you try to access a resource that you do not have the correct scopes for, you will receive the following error. Use the "scopes" property to figure out which scopes you are missing.</p>
  <Codeblock class="mb-5" url="Example error when you are missing a certain scope" :raw-text="JSON.stringify({ 'code': '403-100', 'message': 'Missing or invalid scope.', 'scopes': [ 'session:read' ] }, null, 2)"></Codeblock>

</template>

<script>

import Codeblock from "@/components/layout/codeblock.vue";
import {apiBASE} from "@/lib/http";

export default {
  name: 'Api Documentation Auth',
  components: {
    Codeblock
  },
  mounted() {
    this.$store.commit('setTitle', 'Api Documentation')
  },
  methods: {
    apiBASE() {
      return apiBASE
    }

  },
  computed: {
  }
}
</script>
