<template>

  <div class="bg-menu" >
  <form @submit.prevent="submit()" class="rounded p-2">

    <div class="text-white">
      <label for="url" class="block text-sm font-medium">URL to shorten</label>
      <div class="mt-1">
        <input
            type="url"
            ref="url"
            name="url"
            id="url"
            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            placeholder="https://engrish.com/"
        />
      </div>
      <p class="mt-2 text-sm text-gray-400" id="url-description">Paste the URL you want to shorten with a Awoo.to link.</p>
    </div>

    <br>

    <div class="text-white">
      <label for="url" class="block text-sm font-medium">Custom Name</label>
      <div class="mt-1">
        <input
            :disabled="!user || !user.premium"
            type="text"
            ref="custom"
            name="custom"
            id="custom"
            class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            placeholder="notrickroll"
        />
      </div>
      <p v-if="!user || !user.premium" class="mt-2 text-sm text-gray-400" id="custom-description">Only premium users can use custom names for links.</p>
      <p v-else class="mt-2 text-sm text-gray-400" id="custom-description">Enter the custom name you want for your link.</p>
    </div>

    <br>

    <button class="button bg-black bg-opacity-25 rounded-md p-2.5" type="submit">Create link</button>

  </form>
  </div>

</template>

<script>

export default {
  name: 'ShortenForm',
  data(){
    return {
      link: null
    }
  },
  components: {
  },
  methods: {
    submit() {

      if(!this.$refs.url.value) return this.$notify({ type: 'error', text: 'Please enter a URL to shorten'});

      this.$api.v2.post('/shortener', {
        url: this.$refs.url.value,
        custom: this.$refs.custom.value
      }).then(res => {
        this.link = 'https://awoo.to/' + res.data.id;
        location.reload();
      }).catch(err => {
        console.log('err', err);
        if(err.response && err.response.data && err.response.data.message) return this.$notify({ type: 'error', text: err.response.data.message, duration: -1});
        this.$notify({ type: 'error', text: err.message, duration: -1})
      })
    }
  },
  computed:{
    user(){
      return this.$store.state.user
    }
  }
}
</script>

<style scoped lang="less">

</style>