<template>
  <table class="min-w-full divide-y divide-gray-700 p-2">
    <thead>
      <tr>
        <th>Original URL</th>
        <th>Shortened URL</th>
        <th>Views</th>
      </tr>
    </thead>
    <tr v-for="short in shorturls" :key="short.id" @click="navigateLink(short.id)" class="hover:bg-black bg-opacity-25 cursor-pointer">
      <short-list-item class="result" :obj="short"/>
    </tr>
  </table>

  <InfiniteLoading @infinite="load" />

</template>

<script>

import InfiniteLoading from "v3-infinite-loading";
import shortListItem from "@/components/data/shorturl/shortListItem.vue";

export default {
  name: 'fileList',
  components: {
    InfiniteLoading,
    shortListItem
  },
  data() {
    return {
      shorturls: [],
      page: 0,
      perPage: 25,
    };
  },
  mounted() {
  },
  methods: {

    navigateLink(id){
      this.$router.push(`/link/${id}`)
    },

    async load($state){

      try {

        const response = await this.$api.v2.get(`/shortener?offset=${this.page * this.perPage}&limit=${this.perPage}`);

        if(response.status === 204){
          return $state.complete();
        }

        if(response.status !== 200) return $state.error();
        if(!response.data || !response.data.data) return $state.error();

        this.shorturls.push(...response.data.data);

        if (response.data.data.length < 10) {
          return $state.complete();
        }

        $state.loaded();
        this.page++;

      } catch (error) {
        $state.error();
      }
    }

  },
  computed: {
    user() {
      return this.$store.state.user
    }
  }
}

</script>

<style>

</style>