<template>
  <router-link :to="url" class="relative">

    <template v-if="display === 'grid' || display === 'mason'">

      <div class="bg-white bg-opacity-25 mt-2 bg-menu rounded-md grid grid-cols-1 gap-2 overflow-hidden" @mouseover="hover = true" @mouseleave="hover = false">
        <div class="">
          <img v-if="thumbnail" :src="thumbnail" class="w-full" />
          <div v-else class="text-center" style="width: 100%; ">
            <svg-icon class="inline mr-2 preview" type="mdi" :path="icon" size="144"/>
          </div>
        </div>

        <span class="truncate break-all p-2" v-if="display !== 'mason'">
          {{ file.filename }}<br>
          <i>{{ filesize }}</i>
        </span>

      </div>

      <div class="absolute top-5 right-3 flex gap-2">
        <svg-icon v-if="file.public" type="mdi" :path="mdiEarth()" class="inline text-orchid-600 bg-white bg-opacity-75 rounded-full" size="24"/>
        <svg-icon v-if="file.scheduled_for_deletion" type="mdi" :path="mdiTrashCanOutline()" class="inline text-red-500 bg-white bg-opacity-75 rounded-full" size="24"/>
      </div>

    </template>

    <template v-if="display === 'list'">
      <div class="flex gap-5 bg-white bg-opacity-25 p-4 mt-2 bg-menu rounded-md align-middle">
        <div class="text-center">
          <svg-icon class="inline mr-2" type="mdi" :path="icon" size="24"/>
        </div>
        <div class="col-span-3">
          <p class="truncate text-clip">
            {{ file.filename }}
          </p>
          <p class="text-xs text-gray-100">
            {{ filesize }}
          </p>
        </div>
        <div class="flex gap-2 flex-end ml-auto">
          <svg-icon v-if="file.public" type="mdi" :path="mdiEarth()" class="inline text-orchid-600 bg-white bg-opacity-75 rounded-full" size="24"/>
          <svg-icon v-if="file.scheduled_for_deletion" type="mdi" :path="mdiTrashCanOutline()" class="inline text-red-500 bg-white bg-opacity-75 rounded-full" size="24"/>
<!--          <svg-icon v-if="file.metadata.ocr" type="mdi" :path="mdiCursorText()" class="inline text-blue-500 bg-white bg-opacity-75 rounded-full" size="24"/>-->
        </div>
        <svg-icon type="mdi" :path="mdiChevronRight()" class="inline text-white" size="24"/>
      </div>
    </template>



  </router-link>
</template>

<script>

import FileItemAudio from "@/components/data/files/types/audio.vue";
import FileItemVideo from "@/components/data/files/types/video.vue";
import FileItemImage from "@/components/data/files/types/image.vue";
import {getFileIcon, prettyPrintFileSize} from "@/lib/files";
import svgIcon from "@jamescoyle/vue-icon";
import {
  mdiChevronRight,
  mdiCursorText,
  mdiEarth,
  mdiShare,
  mdiShareCircle,
  mdiTrashCan,
  mdiTrashCanOutline
} from "@mdi/js";

export default {
  name: 'fileListItem',
  methods: {
    mdiCursorText() {
      return mdiCursorText
    },
    mdiTrashCanOutline() {
      return mdiTrashCanOutline
    },
    mdiTrashCan() {
      return mdiTrashCan
    },
    mdiChevronRight() {
      return mdiChevronRight
    },
    mdiEarth() {
      return mdiEarth
    },
    mdiShare() {
      return mdiShare
    },
    mdiShareCircle() {
      return mdiShareCircle
    }
  },
  components: {
    svgIcon,
    FileItemImage,
    FileItemVideo,
    FileItemAudio,
  },
  props: {
    file: Object,
    display: 'grid'
  },
  data(){
    return {
      hover: false
    }
  },
  computed: {
    url(){
      return '/file/' + this.file.id;
    },
    icon() {
      return getFileIcon(this.file.mime);
    },
    filesize(){
      return prettyPrintFileSize(this.file.size);
    },
    thumbnail(){

      // Priority to gifs if it exists
      if( this.file?.urls?.preview_gif && this.hover ){
        return this.file.urls.preview_gif;
      }
      if( this.file.mime === "image/gif" && this.hover ){
        return this.file.urls.full;
      }

      if(this.file?.thumbnails?.tile && this.display === 'mason'){
        return this.file.thumbnails.tile;
      }

      if(this.file?.thumbnails?.header && this.display === 'grid'){
        return this.file.thumbnails.header;
      }

      return false;
    }
  }
}

</script>

<style lang="less" scoped>

.list-item {
  display: block;
  width: 100%;
  height: 100%;
}

/deep/ .preview {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

</style>