<template>
  <router-link :to="url">
    <div class="">
      <div class="items-center" style="">
        <img :src="file.urls.waveform" class="preview bg-menu rounded-md" :class="{ preview: preview}" />
      </div>
    </div>
  </router-link>
</template>

<script>

import InfiniteLoading from "v3-infinite-loading";
import {getFileIcon} from "@/lib/files";
import {mdiDownload, mdiFileImage} from "@mdi/js";
import SvgIcon from '@jamescoyle/vue-icon'
import {prettyPrintFileSize} from "@/lib/files";

export default {
  name: 'fileItemAudio',
  components: {
    InfiniteLoading,
    SvgIcon
  },
  props: {
    file: Object,
    preview: Boolean
  },
  mounted() {
  },
  methods: {
    download(){
      window.location = this.preview_url;
    }
  },
  computed: {
    mdiDownload() {
      return mdiDownload
    },
    filesize(){
      return prettyPrintFileSize(this.file.size);
    },
    url(){
      return '/file/' + this.file.id;
    },
    icon() {
      const icon = getFileIcon(this.file.mime);
      if(icon === mdiFileImage){
        return false;
      }
      return icon;
    },
  }
}

</script>

<style>

</style>