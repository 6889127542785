<template>

	<div v-if="!user">
		<h2 class="text-2xl">Login Required</h2>
		<p>You need to be logged in to use vods.</p>
	</div>

	<div v-else>

		<div class="flex justify-center gap-2 flex-wrap">

			<!-- Create File -->
			<button class="chip disabled">
				<svg-icon type="mdi" :path="mdiPlus" class="inline" />
				Create
			</button>

		</div>

		<br>

		<br>

    <div class="grid grid-cols-1 lg:grid-cols-2 gap-5">
      <div class="rounded-md border-white border-2 p-5">

        <h1 class="text-xl font-title italic font-bold">Stream Archives</h1>
        <p>VODs (Twitch Archives) are automatically imported from any linked Twitch account, If the vod contains stream markers with the description "SWITCH_SCENE|IDLE" and "SWITCH_SCENE|{ANYTHING ELSE}" it will automatically remove those segments.<br>VODs listed here will only be available for ~14 days.</p>

        <br>

        <grid ref="vodgrid" endpoint="vods" mode="grid grid-flow-row-dense grid-cols-1">
          <template #items="props">
            <div v-for="item in props.list" :key="item.id">
              <VodListItem :vod="item" />
            </div>
          </template>
        </grid>
      </div>

      <div class="rounded-md border-white border-2 p-5">
        <h1 class="text-xl font-title italic font-bold">Recent Events</h1>
        <p>Recent events that were caught and recorded. </p>

        <br>

        <grid ref="eventsgrid" endpoint="actionlog" mode="grid grid-flow-row-dense grid-cols-1">
          <template #items="props">
            <div v-for="item in props.list" :key="item.id">
              <ActionLog :log="item" />
            </div>
          </template>
        </grid>
      </div>

    </div>
  </div>



</template>

<script>

import Grid from "@/components/grid/Grid.vue";
import svgIcon from "@jamescoyle/vue-icon";
import {mdiUpload, mdiImport, mdiMagnify, mdiPlus, mdiGroup} from "@mdi/js";
import {openModal, promptModal} from "jenesius-vue-modal";
import ImportFromURL from "@/components/modals/ImportFromURL.vue";
import DynamicIcon from "@/components/dynamic_icon.vue";
import VodListItem from "@/components/data/vods/VodListItem.vue";
import ActionLog from "@/components/events/actionlog.vue";

export default {
	name: 'VodList',
	components: {
    ActionLog,
    VodListItem,
		DynamicIcon,
		svgIcon,
		Grid,
	},
	data() {
		return {

			mdiUpload: mdiUpload,
			mdiImport: mdiImport,
			mdiSearchWeb: mdiMagnify,
			mdiPlus: mdiPlus,
			mdiGroup: mdiGroup,

			uploadProgress: 0,
			search: "",
			selected_group: null,
			selected_mime: "",

		};
	},

	mounted() {
		this.$store.commit('setTitle', 'My Files');
	},
	methods: {
		reload(){
			this.$refs.filegrid.reset();
		},
		async fileInputChanged(e) {
			for(const file of this.$refs.fileupload.files) {
				this.uploadFile(file);
			}
			this.$refs.fileupload.value = null;
		},
		uploadFile(file_data) {
			const name = file_data.name;

			// Request a signed URL from the API
			this.$api.v2.post('/files', {
				filename: name,
				'content-type': file_data.type ? file_data.type : 'application/octet-stream',
			}).then(res => {
				this.progress = 10;

				const formData = new FormData();
				Object.keys(res.data.upload_to.fields).forEach(key => {
					formData.append(key, res.data.upload_to.fields[key]);
				});

				// Actual file has to be appended last.
				formData.append("file", file_data);

				const xhr = new XMLHttpRequest();
				xhr.upload.addEventListener('progress', (event) => {
					console.log('progress', event);
					if (event.lengthComputable) {
						this.uploadProgress = (event.loaded / event.total) * 100;
					}
				});
				xhr.onload = () => {
					if(xhr.status === 204){
						this.uploadProgress = 100;

						this.$api.v2.put('/files/' + res.data.id, {
							status: 'uploaded',
						}).then(res => {
							this.uploadProgress = 0;
							this.$emit('uploaded', res.data);
							this.reload();
						}).catch(err => {
							this.uploadProgress = 0;
							this.reload();
							this.$notify({
								title: "Upload Failed",
								text: "Something went wrong while uploading the file.",
								type: "error"
							});
						});

					}else{
						this.uploadProgress = 0;
						this.reload();
						this.$notify({
							title: "Upload Failed",
							text: "Something went wrong while uploading the file.",
							type: "error"
						});
					}
				};

				xhr.open("POST", res.data.upload_to.url, true);
				xhr.send(formData);

			})

		},

		async showImportDialog() {
			const modal = await promptModal(ImportFromURL);
			this.$api.v2.post('/imports', {
				url: modal
			}).then( result => {
				this.$notify({
					title: "Import Started",
					text: "Your import has been started. It may take a few minutes to complete.",
					type: "success"
				});
			}).catch( err => {
				this.$notify({
					title: "Import Failed",
					text: err.message,
					type: "error"
				});
			})
		}

	},
	computed:{
		filters(){
			let filters = '';
			if(this.search) filters += `&search=${encodeURIComponent(this.search)}`;
			if(this.selected_group) filters += `&collection=${this.selected_group}`;
			if(this.selected_mime) filters += `&type=${this.selected_mime}`;
			return filters;
		},
		user(){
			return this.$store.state.user
		},
		allowedUploadTypes(){
			return '*, */*'
		}
	}
}
</script>
<style scoped lang="less">

@import '@/assets/less/theme.less';

</style>
